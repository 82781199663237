/**
 * Display list of address
 * We use this plugin in account page and confirm page
 *
 * @author David Pocina  <dpocina[at]kooomo[dot]com>
 * @author Fabio Polizzi <fpolizzi[at]kooomo[dot]com>
 *
 */

(function ( $, _ ) { /* global _, handlebarsTemplates, zg_sortElements, ZG_CONFIG */
	'use strict';

	var root = this;

	/**
	 * @selector data-zg-role="address-list" The plugin start if there is the selector in the dom when the page load
	 *
	 */
	var SELECTOR = '[data-zg-role="address-list"]';

	/**
	 * @param {string}         [addressTemplate]   The name of the single address handlebar template.
	 * @param {boolean|string} [addressContainer]  Container where the list should be inserted. By default is the same
	 *                                             element that we use to initialize the script.
	 * @param {number}         [shippingAddressId] ShippingAddressId is the id of the address configured as shipping for
	 *                                             the current session (might be different from the default shipping).
	 *                                             This information is only available as a smarty value so it will be
	 *                                             set in ZG_CONFIG
	 * @param {array}          [addressesSorting]  Sort addresses based on address type.
	 */
	var DEFAULTS = {
		addressTemplate: 'address',
		addressContainer: false,
		shippingAddressId: null,
		addressesSorting: ['billing', 'default_shipping', 'other']
	};


	// ADDRESS LIST CLASS DEFINITION
	// =============================

	/**
	 *
	 * @param {HTMLElement} element
	 * @param {!Object}     options
	 *
	 * @constructor
	 */
	var AddressList = function ( element, options ) {
		this.$element = $( element );

		this.options = _.extend( {}, DEFAULTS, this.options, options || {} );

		this.addressList = null;

		if ( this.options.addressContainer ) {
			this.$addressContainer = $( this.options.addressContainer );
		} else {
			this.$addressContainer = this.$element;
		}

		this.__setEventHandlers();
	};


	// ADDRESS LIST PRIVATE METHODS
	// ============================

	/**
	 * Get the list of addresses
	 *
	 * @private
	 */
	AddressList.prototype.__init = function () {
		this.$addressContainer
			.addClass( 'loading' )
			.empty();

		root.zgAddressUtils.getAddressList();
	};


	/**
	 * check if the Default shipping address is the same as the billing address
	 *
	 * @returns {boolean}
	 * @private
	 */
	AddressList.prototype.__isSameAddressDefaultShipping = function () {
		return this.addressList && _.findWhere( this.addressList, { type: 'default_shipping', same_address: 1 } );
	};


	/**
	 *
	 * @param {Array} response
	 * @private
	 */
	AddressList.prototype.__parse = function ( response ) {
		if ( response && _.isArray( response.data ) ) {
			// sort the addresses
			this.addressList = response.data.sort( zg_sortElements( {
				attr:    'type',
				pattern: this.options.addressesSorting
			} ) );

			// parse the addresses to add the correct actions
			_.each( this.addressList, function ( address ) {
				if ( address.type === 'billing' ) {
					address.actionEdit        = true;
					address.actionMakeDefault = !this.__isSameAddressDefaultShipping();
					address.actionDelete      = false;
					// enable invoice fields for the edit action
					address.enableInvoiceFields = true;
					address.isCurrentShipping   = false;
				} else if ( address.type === 'default_shipping' ) {
					address.actionEdit          = !address.same_address;
					address.actionMakeDefault   = false;
					address.actionDelete        = false;
					address.enableInvoiceFields = false;
					// shippingAddressId is not set. We use 'default_shipping' as the
					// 'isCurrentShipping' address
					address.isCurrentShipping = !(this.options.shippingAddressId);
				} else if ( address.type === 'other' ) {
					address.actionEdit          = true;
					address.actionMakeDefault   = true;
					address.actionDelete        = true;
					address.enableInvoiceFields = false;
					address.isCurrentShipping   = false;
				}

				// set up which is the current shipping address
				if (
					this.options.shippingAddressId &&
					address.address_id == this.options.shippingAddressId
				) {
					address.isCurrentShipping = true;
				}
			}, this );

			this.__render();
		}
	};


	/**
	 * Render the list of addresses
	 *
	 * @private
	 */
	AddressList.prototype.__render = function () {
		if ( this.options.addressTemplate ) {
			this.$addressContainer
					.html( handlebarsTemplates.render( this.options.addressTemplate, this.addressList || [] ) )
					.removeClass( 'loading' );
		}
	};


	/**
	 * When the list of the addresses are loaded by ajax, parse the result object
	 * @private
	 *
	 * @method __setEventHandlers
	 * @listen document#addressUtils.addressReady When the list of the addresses are loaded by ajax, parse the result object
	 *
	 */
	AddressList.prototype.__setEventHandlers = function () {
		$( document ).on('addressUtils.addressReady', function ( e, addressList ) {
			this.__parse( addressList );
		}.bind(this) );
	};


	// ADDRESS LIST PLUGIN DEFINITION
	// ==============================

	function Plugin ( option ) {
		return this.each( function () {
			var $this   = $( this );
			var data    = $this.data( 'zg.addressList' );
			var options = _.extend( {}, ZG_CONFIG || {}, $this.data(), typeof option === 'object' && option );

			if ( !data ) {
				$this.data( 'zg.addressList', ( data = new AddressList( this, options ) ) );
			}

			data.__init();
		} );
	}

	$.fn.zgAddressList             = Plugin;
	$.fn.zgAddressList.Constructor = AddressList;


	// ADDRESS LIST DATA-API
	// ======================

	$( function () {
		$( SELECTOR ).each( function () {
			Plugin.call( $( this ) );
		} );
	} );

}.call( this, jQuery, _ ));
