/**
 * zg-fidelity.js
 *
 * Plugin for manage  fidalty card in account and cart page
 *
 * @author: @author: Andrea Basile <abasile [at] koooomo [dot] com>
 *
 */

/**
 * @event document#submit.zg.comment Submit the comment
 * @type {object}
 * @property {string} content - text of the comment
 * @property {int} productId - id of the product commented.
 * @property {int} score - score value of product.
 */

/**
 * @event document#click.zg.fidelityFormSubmit.Form use points on cart
 * @type {object}
 * @property {string} cardCode
 * @property {string} points
 *
 * @event document#click.zg.registerFidelityCardSubmit. don't work ask to BE
 * @type {object}
 * @property {string} cardCode
 *
 * @event document#click.zg.deleteFidelityDiscount. On submit remove points on cart
 * @type {object}
 * @property {string} cardCode
 *
 * @event document#click.zg.fidelityCardInfoRefreshSubmit. Refresh card data
 * @type {object}
 * @property {string} cardCode
 *
 *
 */

/* global JS_TRANSLATIONS */
(function ($, _) {

    'use strict';

    var root = this;

    /**
     * @selector data-zg-role="fidelity" The plugin start if there is the selector in the dom when the page load
     */

    var selector = '[data-zg-role="fidelity"]';

    /**
     * @param {int} [cardCode] the code of the fidelity card
     * @param {int} [usablePoints] usable points on the card
     * @param {int} [usableValue] usable points value on the card
     * @param {int} [totalPoints] total points on the card
     * @param {int} [totalValue] total value of the points on the card
     * @param {int} [totalCart] total of cart value
     *
     * @param {string} [getFidelityInfo] selector for the fidelity info
     * @param {string} [getPrintableCard] selector for printable card
     * @param {string} [getPrintableCardSubmit] selector submit printable card
     * @param {string} [linkFidelityCardSubmit] selector for submit button
     * @param {string} [fidelityCardInfoRefreshSubmit] selector for refresh submit card data
     *
     * @param {string} [useFidelityPointsSubmit] selector for input using points
     * @param {string} [registerFidelityCardSubmit] selector for sumbit a card number
     * @param {string} [checkFidelityCardStatus] selector for check the card status
     * @param {string} [deleteFidelityDiscount] selector for button to remove discount on cart
     * @param {string} [fidelityCode] selector for card code
     * @param {string} [fidelityEmail] selector for tcard email
     * @param {string} [fidelityPhone] selector for card phone
     * @param {string} [fidelityUsablePoins] selector for usable points
     * @param {string} [fidelityUsableValue] selector for usable points value on the card
     * @param {string} [fidelityTotalPoints] selector for total points on the card
     * @param {string} [fidelityTotalValue] selector for total value of the points on the card
     * @param {string} [totalCartSelector] selector for total of cart value
     *
     */

    var DEFAULTS = {
        //DATA
        cardCode: 0,
        usablePoints:0,
        usableValue:0,
        totalPoints:0,
        totalValue:0,
        totalCart:0,
        fidelityEmail:'',
        fidelityPhone:'',

        //SELECTOR
        getFidelityInfo : '[data-zg-role="get-fidelity-info"]',
        linkFidelityCardForm : '[data-zg-role="link-fidelity-card-form"]',
        fidelityCardInfoRefreshSubmit : '[data-zg-role="fidelity-card-info-refresh-submit"]',

        fidelityFormSubmit : '[data-zg-role="fidelity-form-submit"]',
        registerFidelityCardSubmit : '[data-zg-role="register-fidelity-card-submit"]',
        checkFidelityCardStatus : '[data-zg-role="check-fidelity-card-status"]',
        fidelityCode :	'[data-zg-role="fidelity-code"]',
        deleteFidelityDiscount : '[data-zg-role="delete-fidelity-discount"]',
        fidelityUsingPoints : '[data-zg-role="fidelity-using-points"]',
        fidelityUsablePoints : '[data-zg-role="fidelity-usable-points"]',
        fidelityUsableValue : '[data-zg-role="fidelity-usable-value"]',
        fidelityTotalPoints : '[data-zg-role="fidelity-total-points"]',
        fidelityTotalValue : '[data-zg-role="fidelity-total-value"]',
        customfieldSelector	: '[data-zg-role="customField"]',
        getPrintableCardSubmit : '[data-zg-role="get-printable-card-submit"]',
        getPrintableCard : '[data-zg-role="get-printable-card"]',
        fidelityImage : '[data-zg-role="fidelity-image"]'
    };

    /**
     /**
     *
     * @param {HTMLElement} element
     * @param {!object}     options
     *
     * @constructor
     */

    var Fidelity = function ( element, options ) {
        this.$element                           = $( element );
        this.options                            = _.extendOwn( _.clone( DEFAULTS ), options || {} );

        this.$fidelityInfo                      = this.$element.find( this.options.getFidelityInfo );
        this.$printableCard                     = this.$element.find( this.options.getPrintableCard );
        this.$printableCardSubmit               = $( this.options.getPrintableCardSubmit );
        this.$linkFidelityCardForm           = this.$element.find( this.options.linkFidelityCardForm );
        this.$fidelityCardInfoRefreshSubmit     = this.$element.find( this.options.fidelityCardInfoRefreshSubmit );
        this.$fidelityFormSubmit           			= this.$element.find( this.options.fidelityFormSubmit );
        this.$registerFidelityCardSubmit        = this.$element.find( this.options.registerFidelityCardSubmit );
        this.$checkFidelityCardStatus           = this.$element.find( this.options.checkFidelityCardStatus );
        this.$deleteFidelityDiscount            = this.$element.find( this.options.deleteFidelityDiscount );
        this.$fidelityUsingPoints            = this.$element.find( this.options.fidelityUsingPoints );
        this.$deleteFidelityDiscount            = this.$element.find( this.options.deleteFidelityDiscount );
        this.$fidelityUsingPoints            = this.$element.find( this.options.fidelityUsingPoints );
        this.$fidelityImage = this.$element.find( this.options.fidelityImage );
        // initialize object
        this.__eventHandlers();
    };

    /*

     * @method linkFidelityCardSubmit
     * it don't work! Error in creation with correct card number
     */

    Fidelity.prototype.linkFidelityCardForm = function () {
        this.$element.addClass('loading');
        var $customChecks=this.$element.find($(this.options.customfieldSelector));
        var url = window.makeUrl({module: 'eshop', manager: 'fidelity', action: 'linkCardToAccount'});
        this.options.cardCode = this.$element.find( this.options.fidelityCode ).val();
        var customtosendfield=[];
        $.each($customChecks ,function(key, input) {
            var $input = $(input);
            switch ($input.attr('type')) {
                case('checkbox'):
                    if($input.prop('checked')) {
                        customtosendfield.push({
                            'input': $input.attr('name'),
                            'object_id': $input.data('objectid'),
                            'value': 1
                        });
                    }
                    break;
            }
        });
        var data = {
            cardCode : this.options.cardCode,
            customfields:customtosendfield
        };
        $.ajax({
            type: 'post',
            url: url,
            dataType: 'json',
            data: data,
            success:  _.bind( function ( response ) {
                if (response && response.status === 'success' ){
                    location.reload();
                } else {
                    if(response.message === 'ERROR: '){
                        $(document).trigger('zg-error', [{
                            eventType: 'fidelity-linkCardToAccount',
                            message: JS_TRANSLATIONS.fidelity_cardmissing
                        }]);
                    }else {
                        $(document).trigger('zg-error', [{
                            eventType: 'fidelity-linkCardToAccount',
                            message: response.message || JS_TRANSLATIONS.genericErrorMsg
                        }]);
                    }
                }
                this.$element.removeClass( 'loading' );
            }, this ),
            error: _.bind( function (  ) {
                this.$element.removeClass( 'loading' );
                $(document).trigger('zg-error', [{
                    eventType: 'fidelity-linkCardToAccount',
                    message:  JS_TRANSLATIONS.genericErrorMsg
                }]);
            }, this )
        });
    };


    /**
     * @method fidelityCardInfoRefreshSubmit
     * @fires document#zg-error Display the error if ajax call for refresh fidality card info not return success
     */

    /**
     * @method fidelityCardInfoRefreshSubmit
     * @fires document#zg-error generic error if ajax call return an error
     */

    /**
     * refresh fidelity card data
     *
     */

    Fidelity.prototype.fidelityCardInfoRefreshSubmit = function () {
        this.$element.addClass('loading');
        var url = window.makeUrl({module: 'eshop', manager: 'fidelity', action: 'getFidelityInfo'});
        this.$fidelityCode    = this.$element.find( this.options.fidelityCode );
        this.$fidelityUsablePoints = this.$element.find( this.options.fidelityUsablePoints );
        this.$fidelityUsableValue = this.$element.find( this.options.fidelityUsableValue );
        this.$fidelityTotalPoints = this.$element.find( this.options.fidelityTotalPoints );
        this.$fidelityTotalValue = this.$element.find( this.options.fidelityTotalValue );

        $.ajax({
            type: 'post',
            url: url,
            dataType: 'json',
            success: _.bind( function ( response ) {
                if (response && response.status === 'success') {
                    this.$fidelityCode.html(response.data.code);
                    this.$fidelityUsablePoints.html(response.data.usablepoints);
                    this.$fidelityUsableValue.html(response.data.usable_currency_value);
                    this.$fidelityTotalPoints.html(response.data.totalpoints);
                    this.$fidelityTotalValue.html(response.data.total_currency_value);
                } else {
                    $(document).trigger('zg-error', [{
                        eventType: 'fidelity-getFidelityInfo',
                        message: response.message || JS_TRANSLATIONS.genericErrorMsg
                    }]);
                }
                this.$element.removeClass( 'loading' );
            }, this ),
            error:  _.bind( function (  ) {
                this.$element.removeClass( 'loading' );
                $(document).trigger('zg-error', [{
                    eventType: 'fidelity-linkCardToAccount',
                    message: JS_TRANSLATIONS.genericErrorMsg
                }]);
            }, this ),
        },this);
    };

    Fidelity.prototype.registerFidelityCardSubmit = function () {
        this.$element.addClass('loading');
        var url = window.makeUrl({module: 'eshop', manager: 'fidelity', action: 'requestFildelityCard'});

        $.ajax({
            type: 'post',
            url: url,
            dataType: 'json',
            data: {},
            success:  _.bind( function ( response ) {
                if (response.status === 'success'){
                    $(document).trigger('zg-notification', [{
                        eventType: 'fidelity-requestcardfilelity',
                        message: response.message || JS_TRANSLATIONS.genericErrorMsg
                    }]);
                }else{
                    $(document).trigger('zg-error', [{
                        eventType: 'fidelity-requestcardfilelity',
                        message:response.message || JS_TRANSLATIONS.genericErrorMsg
                    }]);
                }
                this.$element.removeClass( 'loading' );
            }, this ),
            error:  _.bind( function (  ) {
                $(document).trigger('zg-error', [{
                    eventType: 'fidelity-deleteFidelityDiscount',
                    message: JS_TRANSLATIONS.genericErrorMsg
                }]);
                this.$element.removeClass( 'loading' );
            }, this ),
        },this);
    };

    /**
     * @method fidelityFormSubmit
     * @fires document#zg-error Display the error if ajax call for use fidelity points not return success
     */

    /**
     * @method fidelityFormSubmit
     * @fires document#zg-error generic error if ajax call return an error
     */

    /**
     * add points in cart used for discount
     *
     */

    Fidelity.prototype.fidelityFormSubmit = function () {
        this.$fidelityFormSubmit.addClass('loading');
        var url = window.makeUrl({module: 'eshop', manager: 'fidelity', action: 'useFidelityPoint'});
        var data = {cardCode:this.options.cardCode,point:this.$fidelityUsingPoints.val() ,cartTotal:this.options.totalCart};
        $.ajax({
            type: 'post',
            url: url,
            dataType: 'json',
            data: data,
            success: _.bind( function ( response ) {
                if (response && response.status === 'success'){
                    location.reload();
                } else {
                    $(document).trigger('zg-error', [{
                        eventType: 'fidelity-getFidelityInfo',
                        message: response.message || JS_TRANSLATIONS.genericErrorMsg
                    }]);
                    this.$fidelityFormSubmit.removeClass('loading');
                }
            }, this ),
            error:  _.bind( function ( ) {
                $( document ).trigger( 'zg-error', [{
                    eventType: 'fidelity-getFidelityInfo',
                    message:   JS_TRANSLATIONS.genericErrorMsg
                }] );
                this.$fidelityFormSubmit.removeClass('loading');
            }, this )
        },this);

    };

    /*
    *WRONG, TO TEST
    *
    * */
    Fidelity.prototype.getPrintableCard = function () {
        this.$fidelityImage.addClass('hidden');
        var url = window.makeUrl({module: 'eshop', manager: 'fidelity', action: 'getPrintableCard'});
        $.ajax({
            type: 'post',
            url: url,
            dataType: 'json',
            data: { cardCode: this.options.cardCode },
            success:  _.bind( function ( response ) {
                if (response && response.status === 'success'){
                    this.$fidelityImage.attr('src',response.data.card);
                    this.$fidelityImage.removeClass('hidden');
                } else {
                    $(document).trigger('zg-error', [{
                        eventType: 'fidelity-getPrintableCard',
                        message: response.message || JS_TRANSLATIONS.genericErrorMsg
                    }]);
                }
            }, this ),
            error:  _.bind( function (  ) {
                $(document).trigger('zg-error', [{
                    eventType: 'fidelity-getPrintableCard',
                    message: JS_TRANSLATIONS.genericErrorMsg
                }]);
            }, this )
        },this);
    };
    /*
     *WRONG, TO TEST
     *
     * */
    Fidelity.prototype.getPrintableCardSubmit = function () {
        var frameSrc = '/eshop/fidelity/action/printcard/';
        var $modal = this.$printableCardSubmit.find('iframe');
        $modal.attr('src',frameSrc);
    };

    /**
     * @method deleteFidelityDiscount
     * @fires document#zg-error mesasge error with problem
     */

    /**
     * @method deleteFidelityDiscount
     * @fires document#zg-error generic error if ajax call return an error
     */

    /**
     * remove points used from cart before paying
     *
     */

    Fidelity.prototype.deleteFidelityDiscount = function () {
        this.$fidelityFormSubmit.addClass('loading');
        var url = window.makeUrl({module: 'eshop', manager: 'fidelity', action: 'deleteFidelityDiscount'});
        $.ajax({
            type: 'post',
            url: url,
            dataType: 'json',
            success: function (response) {
                if (response && response.status === 'success'){
                    //SUBMIT FORM
                    location.reload();
                } else {
                    this.$fidelityFormSubmit.removeClass('loading');
                    $(document).trigger('zg-error', [{
                        eventType: 'fidelity-deleteFidelityDiscount',
                        message: response.message || JS_TRANSLATIONS.genericErrorMsg
                    }]);
                }
            },
            error: function () {
                this.$fidelityFormSubmit.removeClass('loading');
                $(document).trigger('zg-error', [{
                    eventType: 'fidelity-deleteFidelityDiscount',
                    message: JS_TRANSLATIONS.genericErrorMsg
                }]);
            }

        },this);

    };


    /**
     * @method __eventHandlers
     * @listen document#click.zg.fidelityFormSubmit. On submit use points on cart
     *
     * @method __eventHandlers
     * @listen document#click.zg.registerFidelityCardSubmit. don't work ask to BE
     *
     * * @method __eventHandlers
     * @listen document#click.zg.deleteFidelityDiscount. On submit remove points on cart
     *
     * @method __eventHandlers
     * @listen document#click.zg.fidelityCardInfoRefreshSubmit. Refresh card data
     *

     */

    Fidelity.prototype.__eventHandlers = function () {
        //edit on sbbmit
        this.$fidelityFormSubmit.on('submit.zg.fidelityFormSubmit', (function ( event ) {
            event.preventDefault();
            this.fidelityFormSubmit();
        }).bind( this ) );

        this.$fidelityCardInfoRefreshSubmit.on('click.zg.fidelityCardInfoRefreshSubmit', (function ( event ) {
            event.preventDefault();
            this.fidelityCardInfoRefreshSubmit();
        }).bind( this ) );

        this.$linkFidelityCardForm.on('submit.zg.linkFidelityCardForm', (function ( event ) {
            event.preventDefault();
            this.linkFidelityCardForm();
        }).bind( this ) );

        this.$registerFidelityCardSubmit.on('click.zg.registerFidelityCardSubmit', (function ( event ) {
            event.preventDefault();
            this.registerFidelityCardSubmit();
        }).bind( this ) );

        this.$deleteFidelityDiscount.on('click.zg.deleteFidelityDiscount', (function ( event ) {
            event.preventDefault();
            this.deleteFidelityDiscount();
        }).bind( this ) );

        this.$printableCard.on('click.zg.printableCard', (function ( event ) {
            event.preventDefault();
            this.getPrintableCard();
        }).bind( this ) );
        /*
        this.$printableCardSubmit.on('click.zg.printableCardSubmit', (function ( event ) {
            event.preventDefault();
            this.getPrintableCardSubmit();
        }).bind( this ) );
        */
        this.$printableCardSubmit.on('shown.bs.modal',( function(){
            this.getPrintableCardSubmit();
        }).bind( this ) );
    };



    // Fidelity PLUGIN DEFINITION
    // =========================
    function Plugin ( option ) {
        return this.each( function () {
            var $this   = $( this );
            var data    = $this.data( 'zg.fidelity' );
            var options = $.extend( {}, root.ZG_CONFIG || {}, $this.data(), typeof option === 'object' && option );

            if ( !data ) {
                $this.data( 'zg.fidelity', new Fidelity( this, options ) );
            }
        } );
    }

    $.fn.zg_fidelity             = Plugin;
    $.fn.zg_fidelity.Constructor = Fidelity;

    //START PLUGIN
    $( function () {
        $( selector ).each( function () {
            Plugin.call( $( this ) );
        } );
    } );


}).call( this, jQuery, _ );


