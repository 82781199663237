// ############################################
// ############### PRODUCT ZOOM ###############
// ############################################

var selectedThumbnail = '[data-zg-role="select-thumb"]';
var containerZoom = '[data-zg-role="zoom-container"]';


$(document).on('zg.gallery.rendered' ,function(){
	$(document).on('click', '[data-zg-role="zoom-new"]' ,function(){
		$('body').addClass('stop-scrolling');
		var selectedMain = $(this).data('zoom-images');
		$(containerZoom).css('background-image', 'url('+ selectedMain +')');
		$('.zoom-wrapper').css('display','block');
		
		//ADD CORRECT SELECTED THUMB
		$('.btn-thumb').each(function(){
			if($(this).attr('data-zoom-images')==$('.thumbnail.active').attr('data-images')){
				$(this).trigger('mouseover');
			}
		});
	});
	if ($('.thumb-slider a.thumbnail').length > 0 ){
		$('.thumb-slider a.thumbnail').click(function(){
			$('.thumb-slider a.thumbnail').removeClass('active');
			$( this ).addClass('active');
		});	
	}
});


$('#zoomMain img').click(function() {
	// click on the image, put that one in the zoomMain and open the wrapper-zoom
	$('body').addClass('stop-scrolling');
	var selectedMain = $(this).data('zoom-images');
	$(containerZoom).css('background-image', 'url('+ selectedMain +')');
	$('.zoom-wrapper').css('display','block');
	
	//ADD CORRECT SELECTED THUMB
	
	$('.btn-thumb:first').trigger('mouseover');
	$('.btn-thumb').each(function(){
		if($(this).attr('data-zoom-images')==$('.thumbnail.active').attr('data-images')){
			$(this).trigger('mouseover');
		}
	});
	
});

$('.zoom-close').click(function(e){
	e.preventDefault();
	e.stopPropagation();
	$('body').removeClass('stop-scrolling');
	$('.zoom-wrapper').css('display','none');
});

$(document).on('mouseover', selectedThumbnail ,function(){
  var selectedBkg = $(this).attr('data-zoom-images');
	$('.btn-thumb').removeClass('selected');
	$(this).addClass('selected');
	$(containerZoom).css('background-image', 'url('+ selectedBkg +')');
});


$('.zoom-container').on( "mousemove ", function( e ) {
	e.preventDefault();
	e.stopPropagation();
	var eventY = e.pageY;
	var docHeight =  $( window ).height();
	var positionY = (eventY / docHeight) * 100;
	$(this).css('background-position-y', positionY + '%');
});
var touchZoom = false;
$( ".zoom-container" ).bind('touchstart', function (e){
	touchZoom = true;
});

$( ".zoom-container" ).bind('touchend', function (e){
	touchZoom = false;
});
$( ".zoom-container" ).bind('touchmove', function (e){
	if(touchZoom){
		var currentY = e.originalEvent.touches[0].clientY;
		var currentX = e.originalEvent.touches[0].clientX;

		lastY = currentY;
		var eventY = e.pageY;
		var docHeight =  $( window ).height();
		var positionY = (currentY / docHeight) * 100;
		$(this).css('background-position-y', positionY + '%');
		var positionX = (currentX / docHeight) * 200;
		$(this).css('background-position-x', positionX + '%');
	}
});



