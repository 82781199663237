/**
 * Method used for formatting a value according to the parameter set on the Admin Panel
 * (separatedByComma and currencyAfterValue)
 *
 * @author: Dario Lopez <dlopez[at]kooomo[dot]com>
 *
 */

/* global JS_TRANSLATIONS, ZG_CONFIG */

(function () {
    'use strict';

    // Establish the root object ('window' in the browser)
    var root = this;

    /**
     *
     * @param {string}   value           - price
     * @param {boolean} [avoidCurrency]  - Avoid showing the currency symbol
     * @param {string}  [currency]       - current currency symbol ( defaults to store currency symbol )
     *
     * @returns {string}  Formatted price
     */
    var renderPrice = function renderPrice ( value, avoidCurrency, currency ) {
        value = String( value || 0 );
        value.replace( ',', '.' );
        value = parseFloat( value ).toFixed( 2 );

        currency = currency || JS_TRANSLATIONS.currency;

        if ( ZG_CONFIG.separatedByComma ) {
            value = String( value ).replace( '.', ',' );
        }

        if ( !avoidCurrency ) {
            if ( ZG_CONFIG.currencyAfter ) {
                value = value + ' ' + currency;
            } else {
                value = currency + ' ' + value;
            }
        }

        return value;
    };

    root.renderPrice = renderPrice;

}.call( this ));