// add your site specific javascript here
$( document ).ready(function() {
	'use strict';
	

	$.ajaxSetup( {
		cache: true
	} );

	//For crawler
	if ( !window.JS_ENABLED ) {
		$.ajax( {
			url:      window.makeUrl( { module: 'eshop', action: 'setJavascriptEnabled' } ),
			type:     'post',
			dataType: 'json'
		} );
	}
	
	// Product page for desktop scroll
	if ($ ('body.page_product > div').length > 1 ) {
	
		$( window ).scroll( function() {
	
			var heightCnt = ( $( '.main-image-wrapper' ).height() - $( '.product-information' ).height() );
			if ( $( this ).scrollTop()  < 200 ){
				$('.product-information').removeClass('is-scrolled');
				$('.swiper-container-vertical').removeClass('is-scrolled');
				$('.product-information').removeClass('scroll-stopped');
				$('.swiper-container-vertical').removeClass('scroll-stopped');
			}else if ( $( this ).scrollTop()  > 200 && $( this ).scrollTop() < heightCnt ){        // If the page is scrolled more than the main-image-wrapper
				$('.product-information').addClass('is-scrolled');
				$('.swiper-container-vertical').addClass('is-scrolled');
				$('.product-information').removeClass('scroll-stopped');
				$('.swiper-container-vertical').removeClass('scroll-stopped');
			} else if ( $( this ).scrollTop() > heightCnt) {
				$('.product-information').removeClass('is-scrolled');
				$('.swiper-container-vertical').removeClass('is-scrolled');
				$('.product-information').addClass('scroll-stopped');
				$('.swiper-container-vertical').addClass('scroll-stopped');
			}
		});
	}
	

	//Return to top
	$( window ).scroll( function() {
		if ( $( this ).scrollTop() > 200) {        // If the page is scrolled more than 200px
			$( '#return-to-top' ).fadeIn( 200 );
		} else {
			$( '#return-to-top' ).fadeOut( 200 );
		}
	});
	$( '#return-to-top' ).click( function() {
		console.log("clicked button!");
		$( 'body,html' ).animate({
			scrollTop : 0
		}, 500);
	});

	var $searchContainer = $( '#searchContainer' );
	$( '[data-zg-role="btn-search"]' ).click( function(){
		if( $searchContainer.hasClass( 'd-none' ) ){
			$searchContainer.removeClass( 'd-none' );
		}
		else {
			$searchContainer.addClass( 'd-none' );
		}
	});
	
	 


	//Increasing-decreasing qty input
	$( '[data-zg-role="change-qty"]' ).click( function(){
		event.preventDefault();
		var type = $( this ).data( 'type' );
		var $inputQtySibling = $( this ).parent().siblings( '[data-zg-role="quantity"]' );
		var maxQty = $inputQtySibling.data( 'max' );
		var floatQty = $inputQtySibling.data( 'floatQty' );
		var qty = $inputQtySibling.val();
		var step;

		if( floatQty ){
			step = 0.01;
		}
		else {
			step = 1;
		}
		qty = Number(qty);

		$( '[data-zg-role="change-qty"]' ).attr( 'disabled', false );
		if( type == "minus" ){
			//If qty is 0, we will disable the current minus icon.
			//The next time this function will be run, all the change qty icons will be enabled again.
			if( qty == 0 ){
				$( this ).attr( 'disabled', true );
			}
			else{
				qty = qty - step;
			}
		}
		else {
			//If qty is equal to maxQty, we will disable the current plus icon.
			//The next time this function will be run, all the change qty icons will be enabled again.
			if( qty == maxQty ){
				$( this ).attr( 'disabled', true );
			}
			else {
				qty = qty + step;
			}
		}

		$( this ).parent().siblings( '[data-zg-role="quantity"]' ).val( qty );
	});
	
	/* Toggle class active in bootstrap collapse */
	$('[data-toggle="collapse"]').click(function() {
  	$(this).toggleClass( "active" );
	});
	
	
	/* active tabs for Cart checkout login*/
	
	$(".login-checkout-tab li").click(function() {
		$(".login-checkout-tab li").removeClass('high-custom'); 
		$(this).addClass('high-custom'); 
		$(".login-checkout-tab li").removeClass('active');
		$(this).addClass('active'); 
	});
	
	/*(custom checkbox radio */
	
	$('.nav-item-checkout a').click(function() {
			$(".input-payment").prop('checked', false);
			$(".input-payment").prop('checked', true);
  });
	
	$('#0-tab').click(function() {
			$(".input-payment").prop('checked', false);
			$("#paymentRadios0").prop('checked', true);
  });
	

	
	/* CATEGORY PAGE SORT BY DROPDOWN */


	$('.dropdown .dropdown-item').on('click', function(){
    $('#show').text($(this).text());
	});
	$(document).ready(function() {
		$('#show').text($(".dropdown .dropdown-item[id='default-option']").text());
	});
	
				

	/* CATEGORY PAGE FILTER COLLAPSE */
	$('#filter-container').on('show.bs.collapse', function() {
		$('#filter-wrapper').addClass( 'show' );
	});
	$('#filter-container').on('hide.bs.collapse', function () {
		$('#filter-wrapper').removeClass( 'show' );
	});
	
	
	


$(document).on('click','[data-zg-role="open-nav"]',function(e){
	e.preventDefault();
	closeAllNav();
	$( this ).toggleClass('nav-active');
	
	var $this = $( this );
	var id = $( this ).data('open');
	
	if ( $this.hasClass('navbar-toggle') ){ 
		
		if ( $this.hasClass('is-open') ){
			closeAllNav();
		}else{
			openNav( id );
		}
		$( this ).toggleClass('is-open');
		
	}else{
		openNav( id );
	}
	
	
});


$(document).on('click','[data-zg-role="close-nav"]',function(e){
	e.preventDefault();
	
	var $this = $( this );
	var id = $( this ).data('close');
	
	closeNav( id );

});


/* Set the width of the side navigation to 250px */
function openNav(id) {
	
	if( id.indexOf('sidenavMenuMobile') !== -1 || id == 'sidenavFiltersMobile' || id == 'sidenavSortMobile'){
		document.getElementById(id).style.left = "0px";
	}else{
		closeAllNav();
		document.getElementById(id).style.right = "0px";
	}
	if($(window).width()>=480) { 
		document.getElementById("overlay-toggle").style.display = "block";
	}
	
	$('.opensidebar').removeClass("opensidebar");
	$('#'+id).addClass("opensidebar");
}

/* Set the width of the side navigation to 0 */
function closeNav(id) {
	var w=$(document.getElementById(id)).width();

	if( id.indexOf('sidenavMenuMobile') !== -1 || id == 'sidenavFiltersMobile'|| id == 'sidenavSortMobile'){
		document.getElementById(id).style.left = "-"+w+"px";
	}else{
		document.getElementById(id).style.right= "-"+w+"px";
	}
	
	//document.body.style.backgroundColor = "white";
	document.getElementById("overlay-toggle").style.display = "none";
	//$('#'+id).removeClass("open");
	
	$('.opensidebar').removeClass("opensidebar");
	$( '.navbar-toggle' ).removeClass('is-open');
	$( '.nav-active' ).removeClass('nav-active');
	
} 

function closeAllNav() {
	

	/*
	var w=$(document.getElementById('sidenavCart')).width();
	document.getElementById('sidenavSearch').style.right = "-"+w+"px";
	document.getElementById('sidenavAccount').style.right = "-"+w+"px";
	//document.getElementById('sidenavFavorites').style.right = "-"+w+"px";
	document.getElementById('sidenavCart').style.right = "-"+w+"px";
	document.getElementById('sidenavMenuMobile').style.left = "-"+w+"px";
	$('.sidenavMenuMobile').css("left","-"+w+"px");
	
	
	//document.body.style.backgroundColor = "white";
	document.getElementById("overlay-toggle").style.display = "none";
	//$('#'+id).removeClass("open");
	$('.opensidebar').removeClass("opensidebar");
	*/
	$('#sidenavCart.sidenav,#sidenavWish.sidenav,#sidenavSearch.sidenav').css( 'right','' );
	
}

$("#overlay-toggle").click(function() { 
	$( '.nav-active' ).removeClass('nav-active');
	closeAllNav();
});

$('[data-zg-role="close-all"]').click(function() { 
	closeAllNav();
});



//HOVER MAIN MENU
$('#site_header #navbarNavLeft .nav-item').hover(
	function() {
		$(this).find('.dropdown-menu').stop(true).fadeIn(500);
		$(this).toggleClass('show');
	}, function() {
		$(this).find('.dropdown-menu').stop(true).fadeOut(200);
		$(this).toggleClass('show');
	}

);

//FIX MAIN MENU 
$('#site_header #navbarNavLeft .nav-item .h4').click(function(e) {
	//e.preventDefault();
	e.stopPropagation();
});

//HOVER MAIN MENU
$('#site_header #navbarNavCenter .nav-item').hover(
	function() {
		$(this).find('.dropdown-menu').stop(true).fadeIn(500);
		$(this).toggleClass('show');
	}, function() {
		$(this).find('.dropdown-menu').stop(true).fadeOut(200);
		$(this).toggleClass('show');
	}

);

//FIX MAIN MENU 
$('#site_header #navbarNavCenter .nav-item .h4').click(function(e) {
	//e.preventDefault();
	e.stopPropagation();
});
	/* CATEGORY PAGE FILTER MOBILE */
	/*data-zg-role="openFilterMobile" data-open="filter-container"*/
	
	$(document).on('click','[data-zg-role="openFilterMobile"]',function(e){
		e.preventDefault();
		e.stopPropagation();
		var $this = $( this );
		var id = $( this ).data('open');
		
		if ( $this.hasClass('is-open') ){
			$('body').addClass( 'stop-scrolling' );
			$('.btn[data-open=${id}]').removeClass('is-open');
			closeNav( id );
		}else{
			$('body').removeClass( 'stop-scrolling' );
			$('.btn[data-open=${id}]').addClass('is-open');
			openNav( id );
		}
		
		$( this ).toggleClass('is-open')
		
	});
	




	/* HEADER */
	/* dropdown menu in main header menu open with hover event
	$('#site_header ul.navbar-nav li.dropdown').hover(function() {
		$(this).find('.dropdown-menu').stop(true, true).delay(200).fadeIn(300);
	}, function() {
		$(this).find('.dropdown-menu').stop(true, true).delay(200).fadeOut(300);
	});*/
	
	/* dropdown main menu header for desktop version*/
	$('#navbarNav').on('show.bs.dropdown', function () {
		statusOpen();
	});
	$('#navbarNav').on('hide.bs.dropdown', function () {
		statusClose();
	});
	/**/
	
	/* main menu header for mobile version*/
	
	
	/* open/close version navbar menu mobile */
	$(document).on('click','[data-zg-role="openNavbarMobile"]',function(e){
		e.preventDefault();
		e.stopPropagation();
		var $this = $( this );
		var id = $( this ).data('open');
		
		statusClose();
		
		if ( $this.hasClass('is-open') ){
			$(`.navbar-toggler[data-open=${id}]`).removeClass('is-open');
			closeNav( id );
		}else{
			$(`.navbar-toggler[data-open=${id}]`).addClass('is-open');
			statusOpen();
			openNav( id );
		}
	});
	
	/* open/close version nav item II level */
	$(document).on('click','[data-zg-role="openNavItemIIlvl"]',function(e){
		e.preventDefault();
		e.stopPropagation();
		var $this = $( this );
		var id = $( this ).data('open');
		
		if ( $this.hasClass('is-open') ){
			$('#site_header').removeClass( 'menuIIlvlOpen' );
			$(`.navitem-toggler[data-open=${id}]`).removeClass('is-open');
			$(`.nav-link[data-open=${id}]`).removeClass('is-open');
			closeNav( id );
		}else{
			$('#site_header').addClass( 'menuIIlvlOpen' );
			$(`.navitem-toggler[data-open=${id}]`).addClass('is-open');
			$(`.nav-link[data-open=${id}]`).addClass('is-open');
			openNav( id );
		}													
	});
	
	/* Set the width of the side navigation to 0 */
	function closeNav(id) {
		if(id=='sidenavCart' || id=='sidenavWish'){
				document.getElementById(id).style.right = "-100%";
		}else{
			document.getElementById(id).style.left = "-100%";
		}
	}
	
	/* Set the width of the side navigation to 100% */
	function openNav(id) {
		if(id=='sidenavCart' || id=='sidenavWish'){
			document.getElementById(id).style.right = "0";
		}else{
			document.getElementById(id).style.left = "0";
		}
	}
	
	/* collapse main menu */
	$('#navbarNav.navbar-collapse').on('show.bs.collapse', function() {
		$('#navbarSearch .dropdown-menu').removeClass('show');
		$('#navbarCart .dropdown-menu').removeClass('show');
		setTimeout(function() { 
			statusOpen();
    }, 50);
	});
	$('#navbarNav.navbar-collapse').on('hide.bs.collapse', function () {
		statusClose();
	});
	/**/
	
	/* manage open/close nav tool for mobile version*/
	$('#navbarCart').on('show.bs.dropdown', function () {
		$('body').addClass( 'stop-scrolling' );
		$('#site_header').addClass( 'cartOpen' );
	});
											
	$('#navbarCart').on('hide.bs.dropdown', function () {
		$('body').removeClass( 'stop-scrolling' );
		$('#site_header').removeClass( 'cartOpen' );
	});
	
	$('#navbarSearch').on('show.bs.dropdown', function () {
		$('#navbarNav.navbar-collapse').removeClass('show');
		statusOpen();
		$('#site_header').addClass( 'searchOpen' );
	});
	
	$('#navbarSearch').on('hide.bs.dropdown', function () {
		statusClose();
		$('#site_header').removeClass( 'searchOpen' );
	});
	
	function statusOpen (){
		$('body').addClass( 'stop-scrolling' );
		$('#site_header').addClass( 'menuOpen' );
	}
	
	function statusClose (){
		$('body').removeClass( 'stop-scrolling' );
		$('#navbarSearch .dropdown-menu').removeClass('show');
		$('#navbarCart .dropdown-menu').removeClass('show');
		$('#site_header').removeClass( 'menuOpen' );
		$('#site_header').removeClass( 'searchOpen' );
		$('#site_header').removeClass( 'cartOpen' );
	}
	
/**/


	$(document).on('click','[data-zg-role="next-image"]',function(e){
		e.preventDefault();
		console.log('next image');
		if ($('.gallery-product a.active').closest( "div" ).next().length){
			$('.gallery-product a.active').closest( "div" ).next().find('a').trigger('click');
		}else{
			$('.gallery-product a:first').trigger('click');
		}
		
		
	});


	
});



/*
$(document).on('click', '#loginPill',()=>{
	$('#Login_tab').tab('show')
});
$(document).on('click', '#registerPill',()=>{
	$('#Register_tab').tab('show')
});
$(document).on('click', '#guestPill',()=>{
	$('#Guest_tab').tab('show')
});
*/



if ($('.progressbar_container').length ){
    progressBarFreeShipping();
}
        
//Progress bar free shipping
function progressBarFreeShipping(){
const progress = document.querySelector(".progressbar_main-done");
const minValue = document.querySelector(".helper-div").getAttribute('data-done');
const cartValue = document.querySelector(".helper-div-2").getAttribute('data-done');
(cartValue / minValue)*100 < 100 ? progress.style.width =  (cartValue / minValue)*100 + '%' : progress.style.width = 100 + '%'; 
const result= document.querySelector('.result');
let resultdiff = toFixed((minValue-cartValue),2);

const resultdiffX = Number(resultdiff).toFixed(2);
resultdiffX > 0 ? result.innerHTML=""+resultdiffX+"" : 
    (document.getElementById("not-completed").classList.add("d-none"),
     document.getElementById("announce").classList.add("d-none"),
     document.getElementById("completed").classList.remove("d-none")
    );
}

//function to truncate after a number of decimal, when using innerHTML seems that type is not always handled correctly
function toFixed(num, fixed) {
    var re = new RegExp('^-?\\d+(?:\.\\d{0,' + (fixed || -1) + '})?');
    return num.toString().match(re)[0];
}