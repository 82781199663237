/* ============================================================
 * parseString
 *
 *   Clean-up whitespace and trim
 *   Optionally transform the string to lower case
 *
 *
 * @author: David Pocina <dpocina[at]zerogrey[dot]com>
 *
 * ============================================================ */

(function () {
	'use strict';

	// Establish the root object (`window` in the browser)
	var root = this;

	// http://www.w3.org/TR/css3-selectors/#whitespace
	var whitespace = '[\\x20\\t\\r\\n\\f]+';

	var rxWhitespace;

	/**
	 *
	 * @param {string}   string
	 * @param {boolean=} toLowerCase
	 * @param {string=}  whitespaceCharacter
	 *
	 * @returns {string}
	 */
	var parseString = function parseString ( string, toLowerCase, whitespaceCharacter ) {
		// make sure we are working with a string
		var parsed = ( String( string || '' ) ).trim();

		// initialize the whitespace regular expression
		if ( !rxWhitespace ) {
			rxWhitespace = new RegExp( whitespace, 'g' );
		}

		// Whitespace characters to single space (or other character)
		parsed = parsed.replace( rxWhitespace, ( whitespaceCharacter || ' ' ) );

		// change it to lowercase
		if ( toLowerCase ) {
			parsed = parsed.toLowerCase();
		}

		return parsed;
	};

	root.zgParseString = parseString;

}.call( this ));
