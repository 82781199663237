/*
********* TYP GTM DATALAYER PUSH ********
* insert in global/__google_tag_manager.html, if in order page, the pushGTMPayment function
* check fot data-zg-role="zg-thank-you-page" orderid="{$result->order_id}" data-orderid="{$result->order_id}" in orderMgr_details.html
* bypass in the file 2800-zg-thank-you-page's tryingToPay function with:
*       window.localStorage.setItem('state_payment','trying');
*       window.localStorage.setItem('is_payment',self.order_id);
*       paymentCheck();
*/
(function ($) {
    var statePayment = window.localStorage.getItem('state_payment');
    var idPayment = window.localStorage.getItem('is_payment');
    var GTMPayment = window.localStorage.getItem('pushGTMPayment');

    var i = 0,
        intervalTime = 4000,  // 3 seconds
        limit = 40;						// 5 times - 15 seconds
    window.paymentCheck= function(){
        statePayment = window.localStorage.getItem('state_payment');
        idPayment = window.localStorage.getItem('is_payment');
        GTMPayment = window.localStorage.getItem('pushGTMPayment');
        if(idPayment != null){
            var looper = setInterval(

                function() {
                    //console.log(idPayment);
                    var request = {
                            orderId: idPayment
                        },
                        url = window.makeUrl({
                            module: 'eshop',
                            action: 'getStatusOrder'
                        });

                    $.getJSON(url, request, function (response) {
                        //PUSH EVENT PURCHASE IF payment is done == 2
                        idPayment = window.localStorage.getItem('is_payment');
                        if(response.status_id==2 && idPayment != null){
                            //console.log('push datalayer');
                            eval(GTMPayment);
                            pushGTMPayment();
                            clearInterval(looper);
                            localStorage.removeItem('state_payment');
                            localStorage.removeItem('is_payment');
                            localStorage.removeItem('pushGTMPayment');
                            if(i != 0){
                                setTimeout(function(){
                                    location.reload();
                                },1000);
                            }
                        }

                    });

                    if (i >= limit) {
                        // * Stop checking *
                        clearInterval(looper);
                        //clear data in localstore
                        localStorage.removeItem('state_payment');
                        localStorage.removeItem('is_payment');
                        localStorage.removeItem('pushGTMPayment');

                    }

                    i++;
                },

                intervalTime);
        }
    }
    $(document).ready(function() {


        if(statePayment=='trying'){
            paymentCheck();
        }
    });
})(jQuery);