/**
 * Plugin for managing subscriptions on the eshop
 */

/* global  JS_TRANSLATIONS, zgPost */

(function ($, _) {

    'use strict';

    var root = this;

    /**
     * @selector The plugin start if there is the selector in the dom when the page load
     */

    var SELECTOR = '[data-zg-role="order-subscription"]';

    var DEFAULTS = {
        orderSubscriptionCancelSelector : '[data-zg-action="order-subscription-cancel"]',
        orderSubscriptionIdCancelDataSelector : 'subscription-id'
    };

    /**
     *
     * @param {HTMLElement} element
     * @param {!object}     options
     *
     * @constructor
     */

    var EshopOrderSubscription = function ( element, options ) {

        this.$element                   = $( element );
        this.options                    = _.extendOwn( _.clone( DEFAULTS ), options || {} );

        this.__eventHandlers();
    };

    EshopOrderSubscription.prototype.__eventHandlers = function () {
        var that = this;
        $(this.options.orderSubscriptionCancelSelector).on('click', function(e){
            e.preventDefault();

            var orderSubscriptionId = $(this).data(that.options.orderSubscriptionIdCancelDataSelector);
            that.__cancelSubscription(this, orderSubscriptionId);
        });
    };

    EshopOrderSubscription.prototype.__cancelSubscription = function (button, orderSubscriptionId) {

        $(button).addClass('disabled');

        if ( orderSubscriptionId ) {
            zgPost(
                'cancelOrderSubscription',
                { subscription_id: orderSubscriptionId },
                { error: JS_TRANSLATIONS.eshop_cancel_subscription_id_failed,
                  success: JS_TRANSLATIONS.eshop_cancel_subscription_id_success
                },
                {
                    success: function () {
                        $( document ).trigger( 'zg-success', [{
                            message: window.JS_TRANSLATIONS.eshop_cancel_subscription_id_success
                        }]);
                    },
                    error: function () {
                        $( document ).trigger( 'zg-error', [{
                            message: window.JS_TRANSLATIONS.eshop_cancel_subscription_id_success
                        }]);
                    },
                    complete: function(){
                        $(button).removeClass('disabled');
                    }
                }
            );
        } else {
            throw new Error( 'EshopOrderSubscription.__cancelSubscription FAILED - please specify a subscriptionId' );
        }
    };

    function Plugin ( option ) {
        return this.each( function () {
            var $this   = $( this );
            var data    = $this.data( 'zg.eshopOrderSubscription' );
            var options = $.extend( {}, root.ZG_CONFIG || {}, $this.data(), typeof option === 'object' && option );

            if ( !data ) {
                $this.data( 'zg.eshopOrderSubscription', data = new EshopOrderSubscription( this, options ) );
            }
        });
    }

    $.fn.zg_eshopOrderSubscription             = Plugin;
    $.fn.zg_eshopOrderSubscription.Constructor = EshopOrderSubscription;

    //START PLUGIN
    $(function () {
        $(SELECTOR).each(function () {
            Plugin.call($(this));
        });
    });


}).call( this, jQuery, _ );
