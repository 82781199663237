/**
 * exportMissingGeolocation
 *
 *    Create a table with all the stores with missing geolocation.
 *    Creates a csv file to be imported in the CMS.
 *
 *
 * @author: David Pocina <dpocina[at]zerogrey[dot]com>
 *
 */

(function ( $ ) { /* global _, handlebarsTemplates */
	'use strict';

	var root = this;

	var DEFAULTS = {
		container: '[data-zg-role="sl-location-info"]',

		templateError: 'storelocator-missing-geolocation-error',
		templateTable: 'storelocator-missing-geolocation-table'
	};


	// CLASS DEFINITION
	// ================

	var ExportMissingGeolocation = function ( options ) {
		this.options = _.extend( {}, DEFAULTS, window.ZG_CONFIG, typeof options === 'object' && options );

		this.missingGeolocationInfo = {};

		this.isFirst = true;

		this.$container = $( this.options.container );
	};


	/**
	 *
	 * @param {string} storeId
	 * @param {Object} [storeData]
	 * @param {string} [status]
	 */
	ExportMissingGeolocation.prototype.addStore = function ( storeId, storeData, status ) {
		this.missingGeolocationInfo[storeId] = this.missingGeolocationInfo[storeId] || {};

		if ( storeData ) {
			this.missingGeolocationInfo[storeId].data = storeData;
		}

		if ( status ) {
			this.missingGeolocationInfo[storeId].status = status;
		}

		this.__render();
	};

	/**
	 *
	 * @private
	 */
	ExportMissingGeolocation.prototype.__parseStores = function () {
		var data  = {};
		var file  = ['"content_type","content_id","content_name","is_published","language","Latitude","Longitude"'];
		var ready = true;

		var statusTotal = 0;
		var statusType  = {};

		// parse received geolocation to create the blob file
		_.each( this.missingGeolocationInfo, function ( item, key ) {
			statusTotal++;

			// statistics for each store status
			var currentStatus = item && item.status ? item.status : null;
			if ( currentStatus ) {
				statusType[currentStatus] = ( statusType[currentStatus] || 0 ) + 1;
			}

			if ( ready ) {
				if ( _.isEmpty( item.data ) ) {
					ready = false;
				} else if ( item.data.fields.Latitude && item.data.fields.Longitude ) {
					file.push(
						'"' + item.data.type + '",' +
						'"' + key + '",' +
						'"' + item.data.name + '",' +
						'"' + item.data.isPublished ? 1 : 0 + '",' +
						'"default",' + // default language (use for every language)
						'"' + item.data.fields.Latitude + '",' +
						'"' + item.data.fields.Longitude + '"'
					);
				}
			}
		} );

		if ( ready ) {
			data.stores = this.missingGeolocationInfo;
			data.file   = URL.createObjectURL(
				new Blob( [( file.join( '\n' ) )], {type: 'text/csv'} )
			);
		}

		data.status = {
			total: statusTotal,
			types: {}
		};

		_.each( statusType, function ( type, key ) {
			data.status.types[key] = type / ( statusTotal || 1 ) * 100;
		} );

		this.$container.html(
			handlebarsTemplates.render( this.options.templateTable, data )
		);
	};


	/**
	 *
	 * @private
	 */
	ExportMissingGeolocation.prototype.__render = function () {
		if ( ('Blob' in root) && ('URL' in root) ) {

			// create the table
			this.__parseStores();

		} else {

			// current browser is not able to create the file
			this.$container.html(
				handlebarsTemplates.render( this.options.templateError )
			);

		}
	};


	// -----------------------------------------------------------------------------------------------------------------

	root.ZgExportMissingGeolocation = ExportMissingGeolocation;

}.call( this, jQuery ));
