(function ( $, _ ) {
	$( function () {
		window.history.scrollRestoration = 'manual';
		if (ACTUAL_CATEGORY != 0) {

			$(document).on('click', '[data-zg-role="product-link"]', function(event) {		
				var position = $(window).scrollTop();
				$('.loading-overlay').remove();
				var category_id = ACTUAL_CATEGORY;
				zgCreateCookie('last_position', position, 1);
				zgCreateCookie('last_category_id', category_id, 1);
				can_erased=false;
				//console.log(position);
				//console.log(category_id);
			
			});

			var last_position = zgReadCookie('last_position');
			var last_category_id = zgReadCookie('last_category_id');
			var flagOn= true;
			if ( last_position && last_category_id && last_category_id == ACTUAL_CATEGORY ) {

				//$('.loading-overlay').show();
				//$('#category-container').addClass('loading').css('height','auto');
				$(window).on('zg.getProductInfo.complete', function() {
					if(flagOn){
						flagOn= false; 
						setTimeout(function(){  
							$("html, body").animate({ scrollTop: last_position }, 100, 'swing', function() {
								var position = $(window).scrollTop();
								//console.log(position);
								//console.log(last_position);
								if (position >= last_position-50) {
									
									//console.log('ERASE');
									
									zgEraseCookie('last_position');
									zgEraseCookie('last_category_id');
									//$('#category-container').removeClass('loading');
								}
							});
							
							
						}, 1000);
					}
				});	

			}


		}else{
			zgEraseCookie('last_position');
			zgEraseCookie('last_category_id');
		}	
	} );
}( jQuery, _ ));	

