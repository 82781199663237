/* =====================================================================================================================
 * searchString
 *
 *   Search for a string in the object.
 *   It will check a specific property, a group of properties or all properties in the object
 *
 *
 * @author: David Pocina <dpocina[at]zerogrey[dot]com>
 *
 * ================================================================================================================== */

(function ( _ ) { /* global _, zgParseString, zgGetObjectPropertyValue */
	'use strict';

	// Establish the root object (`window` in the browser)
	var root = this;


	/**
	 *
	 * @param {string}        string
	 * @param {Object}        item
	 * @param {string|Array=} fields
	 *
	 * @returns {boolean}
	 */
	function searchString ( string, item, fields ) {
		var valid;

		valid = true;

		if ( _.isObject( item ) && string ) {
			valid = __testRegExp( __createRegExp( string ), item, fields );
		}

		return valid;
	}


	/**
	 * Clean up the search string and create a regular expression.
	 * Each space separated value in the string acts as an option for the search
	 *
	 * @param {string} string
	 * @returns {RegExp}
	 * @private
	 */
	function __createRegExp ( string ) {
		var array = _.map( zgParseString( string, true ).split( ' ' ), function ( item ) {
			return '(' + item + ')+';
		} );

		return new RegExp( array.join( '|' ) );
	}


	/**
	 *
	 * @param {Object}        obj
	 * @param {string|Array} fields
	 *
	 * @returns {Array}
	 *
	 * @private
	 */
	function __getValues ( obj, fields ) {
		var i, values = [], val;

		if ( _.isEmpty( fields ) ) {

			// get all values for all properties
			values = zgGetObjectPropertyValue( obj, null, true );

		} else if ( _.isArray( fields ) ) {

			for ( i = 0; i < fields.length; i++ ) {
				values = _.union( values, __getValues( obj, fields[i] ) );
			}

		} else if ( _.isString( fields ) || obj.hasOwnProperty( fields ) ) {

			val    = zgGetObjectPropertyValue( obj, fields, true );
			values = _.isArray( val ) ? val : [val];

		}

		return values;
	}


	/**
	 *
	 * @param {RegExp} regExp
	 * @param {Object} obj
	 * @param {Array=} fields
	 *
	 * @returns {boolean}
	 *
	 * @private
	 */
	function __testRegExp ( regExp, obj, fields ) {
		var valid = false, values;

		if ( fields ) {

			values = __getValues( obj, fields );
			valid  = __testRegExp( regExp, values );

		} else {

			// go through all properties
			values = _.find( obj, function ( item ) {
				if ( _.isArray( item ) || _.isObject( item ) ) {
					return __testRegExp( regExp, item );
				} else {
					return regExp.test( zgParseString( item, true ) );
				}
			} );

			valid = !_.isUndefined( values );
		}

		return valid;
	}


	// -----------------------------------------------------------------------------------------------------------------


	root.zgSearchString = searchString;

}.call( this, _ ));
