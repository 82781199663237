/**
 * Created by Andrea on 23/05/2018.
 */
/**
 * zg-privacy-policy.js
 *
 * Plugin for manage privacy policy

 *
 * @author: @author: Andrea Basile <abasile [at] koooomo [dot] com>
 *
 */

/* global  JS_TRANSLATIONS */

(function ($, _) {

    'use strict';

    var root = this;

    /**
     * @selector data-zg-role="privacy-policy" The plugin start if there is the selector in the dom when the page load
     */

    var selector = '[data-zg-role="privacy-policy"]';


    var DEFAULTS = {
        //DATA
        //accept_newsletter:'[data-zg-role="accept_newsletter-privacy"]',
        //profiling:'[data-zg-role="profiling-privacy"]',

        btnAcceptPrivacy :      '[data-zg-role="accept-privacy"]',
        accept_newsletter :     '[data-zg-role="accept_newsletter"]',
        statistical_research :  '[data-zg-role="statistical_research"]',
        email :                 '[data-zg-role="email"]',
        privacyModal :          '[data-zg-role="modal-privacy-info"]',
        userId :                '[data-zg-role="user-id"]',
        userInfo :                '[data-zg-role="user-info"]'

    };

    /**
     *
     * @param {HTMLElement} element
     * @param {!object}     options
     *
     * @constructor
     */

    var PrivacyPolicy = function ( element, options ) {
        this.$element                   = $( element );
        this.options                    = _.extendOwn( _.clone( DEFAULTS ), options || {} );

        this.$submitPrivacy             = this.$element.find( this.options.btnAcceptPrivacy );
        this.$profiling                 = this.$element.find( this.options.statistical_research );
        this.$accept_newsletter         = this.$element.find( this.options.accept_newsletter );
        this.$email                     = this.$element.find( this.options.email );
        this.$userId                     = this.$element.find( this.options.userId );
        this.$userInfo                    = this.$element.find( this.options.userInfo );
        this.$privacyModal               = $(this.options.privacyModal);


        // initialize object
        this.__eventHandlers();
        if($('[data-zg-role="privacy-policy"]').length===1) {
            this.$privacyModal.modal();
        }
    };


    PrivacyPolicy.prototype.submitPrivacyPolicy = function () {

            var self = this;
            var url = window.makeUrl({module: 'eshop', manager: 'eshop', action: 'updatePrivacyPolicies'});
            var checkAN=self.$accept_newsletter.is(':checked') ? 1 : 0;
            var profilingAN=self.$profiling.is(':checked') ? 1 : 0;
            var data={} ;
            data['accepts'] = 1;
            //data['user_info'] = $.parseJSON('[' + self.$userInfo.val() + ']')[0] ;
            if(self.$userId.val()!=='0'){
                data['user_id'] = self.$userId.val() ;
            }
            data['user_info'] = {email:self.$email.val()};
            data['accept_newsletter'] = checkAN;
            data['accept_profiling'] = profilingAN;
            $.ajax({
                type: 'post',
                url: url,
                dataType: 'json',
                data: data,
                success:  _.bind( function ( response ) {
                    if (response && response.status === 'success'){
                        this.$element.removeClass('loading');
                        $(document).trigger('zg-notification', [{
                            eventType: 'privacy-policy-accepted',
                            message: response.message || JS_TRANSLATIONS.genericErrorMsg
                        }]);
                    } else {

                        $(document).trigger('zg-error', [{
                            eventType: 'privacy-policy-error',
                            message: JS_TRANSLATIONS.genericErrorMsg
                        }]);
                    }
                },this),
                error:  _.bind( function (  ) {
                    $(document).trigger('zg-error', [{
                        eventType: 'privacy-policy-error',
                        message: JS_TRANSLATIONS.genericErrorMsg
                    }]);
                },this)
            });

    };


    /**
     * @method __eventHandlers
     * @listen document#submit.zg.privacy.policy On submit adds the comment to product
     */

    PrivacyPolicy.prototype.__eventHandlers = function () {

        var self = this;
        this.$element.on('submit.zg.privacyPolicy', (function ( event ) {

            event.preventDefault();
            event.stopPropagation();
            if ( self.$element.data('zgValidator') ) {
                if ( !self.$element.data('zgValidator').validate() ) {
                    return false;
                }
            }
            self.$element.addClass('loading');
            self.submitPrivacyPolicy();
            self.$element.removeClass('loading');
            if( self.$privacyModal &&  self.$privacyModal.hasClass('in')){
                self.$privacyModal.modal('hide');
            }

        }).bind( this ) );
        this.$privacyModal.on('hidden.bs.modal', function () {
            if ( self.$element.data('zgValidator') ) {
                if ( !self.$element.data('zgValidator').validate() ) {
                    return false;
                }
            }
            self.submitPrivacyPolicy();
        });

    };

    // PrivacyPolicy PLUGIN DEFINITION
    // =========================
    function Plugin ( option ) {
        return this.each( function () {
            var $this   = $( this );
            var data    = $this.data( 'zg.privacyPolicy' );
            var options = $.extend( {}, root.ZG_CONFIG || {}, $this.data(), typeof option === 'object' && option );

            if ( !data ) {
                $this.data( 'zg.privacyPolicy', new PrivacyPolicy( this, options ) );
            }
        } );

    }

    $.fn.zg_privacyPolicy             = Plugin;
    $.fn.zg_privacyPolicy.Constructor = PrivacyPolicy;

    //START PLUGIN
    $( function () {
        $( selector ).each( function () {
            Plugin.call( $( this ) );
        } );
    } );


}).call( this, jQuery, _ );
