/**
 * Manage shipping methods in confirm page. Send to backend the shipping method selected and change totals
 *
 * @author: Pietro Vignola <pvignola[at]kooomo[dot]com>
 * @author: David Pocina   <dpocina[at]kooomo[dot]com>
 *
 */

/**
 * @event document#zg-error Generic error. Used by 2002-zg-notifier.js to display the error
 * @type {object}
 * @property {string} eventType - Typology of event.
 * @property {string} message - The error message.
 */

/**
 * @event document#zg.selectShipping.shippingReady Form for select shipping method is ready
 * @type {object}
 * @property {int} - id of shipping method selected
 * @property {string} - shipping info if there is data-shipping-info in html of the selector
 */




(function ( $, _ ) { /* global JS_TRANSLATIONS, makeUrl, ZG_CONFIG */
	'use strict';

	/**
	 * @selector data-zg-role="select-shipping-method" The plugin start if there is the selector in the dom when the page load
	 */

	var selector = '[data-zg-role="select-shipping-method"]';

	/**
	 * @param {string} [selector] Radio button with shipping method value
	 */

	var DEFAULTS = {
		selector: 'input[name="shipping-method"]'
	};

	// SELECT SHIPPING CLASS DEFINITION
	// ================================

	/**
	 *
	 * @param {HTMLElement} element
	 * @param {!object}     options
	 *
	 * @constructor
	 */
	var SelectShipping = function ( element, options ) {
		this.$element = $( element );
		this.options  = _.extend( {}, DEFAULTS, options || {} );

		this.url = makeUrl( {module: 'eshop', manager: 'eshop', action: 'applyShippingMethodToCart'} );

		this.$shipingCost           = $( '[data-zg-role="ship_cost"]' );
		this.$shippingCostContainer = $( '[data-zg-role="shipping-cost-container"]' );

		this.$totalDiscount          = $( '[data-zg-role="total-discount"]' );
		this.$totalDiscountContainer = $( '[data-zg-role="total-discount-container"]' );

		this.$appliedPromotion          = $( '[data-zg-role="applied-promotion"]' );
		this.$appliedPromotionContainer = $( '[data-zg-role="applied-promotion-container"]' );

		this.$totalTax = $( '[data-zg-role="total_tax"]' );
		this.$totalSum = $( '[data-zg-role="total_sum"]' );
        this.$totalSumConverted = $( '[data-zg-role="total_sum_converted"]' );

		this.$payments         = $( '[data-zg-role="payments"]' );

		this.__setEventHandlers();
		this.__init();
	};


	/**
	 * @method request
	 * @fires document#zg-error Shipping not selectable
	 */

	/**
	 * @method request
	 * @fires document#zg.selectShipping.shippingReady After click on shipping method, call ajax for change it and set shipping details in the page
	 */

	SelectShipping.prototype.request = function ( shippingId, shippingInfo ) {
		this.$element.addClass( 'loading' );

		$.ajax( {
			type:     'POST',
			url:      this.url,
			dataType: 'json',

			data: {
				'shipping_select': shippingId || 0
			},

			success: (function ( response ) {
				this.__updateData( response );

				this.$payments.removeClass( 'd-none' );

				$( document ).trigger( 'zg.selectShipping.shippingReady', [shippingId, shippingInfo] );
			}).bind( this ),

			error: function () {
				this.$payments.addClass( 'd-none' );

				$( document ).trigger(
					'zg-error',
					[{
						eventType: 'SelectShipping',
						message:   JS_TRANSLATIONS.shipping_not_selectable || 'Shipping not selectable'
					}]
				);
			},

			complete: (function () {
				this.$element.removeClass( 'loading' );
			}).bind( this )
		} );
	};


	/**
	 * Select the first shipping method if none is selected
	 *
	 * @private
	 */
	/**
	 * @method __init
	 * @fires document#zg.selectShipping.shippingReady On init of the plugin, check if there is a method checked otherwise click the first
	 */
	SelectShipping.prototype.__init = function () {
		var $selected = this.$element.find( this.options.selector + ':checked' );

		if ( $selected.length ) {
			$( document ).trigger( 'zg.selectShipping.shippingReady', [$selected.val(), $selected.data( 'shipping-info' )] );
		} else {
			this.$element.find( this.options.selector ).first().prop( 'checked', true ).change();
		}
	};

	/**
	 *
	 * @param response
	 * @private
	 */
	SelectShipping.prototype.__updateData = function ( response ) {
		if ( response.shipping_cost === '0.00' ) {
			this.$shippingCostContainer.addClass( 'd-none' );
		} else {
			this.$shippingCostContainer.removeClass( 'd-none' );
		}
        if( response.shipping_cost_converted ){
            this.$shipingCost.html( window.renderPrice( response.shipping_cost_converted ) + ' (' + window.renderPrice( response.shipping_cost, null, window.ZG_CONFIG.original_currency ) + ')' );
        }
        else{
            this.$shipingCost.html( window.renderPrice( response.shipping_cost ) );
        }

		if ( response.discountAmountNoShipCostDiscount === '0.00' ) {
			this.$totalDiscountContainer.addClass( 'd-none' );
		} else {
			this.$totalDiscountContainer.removeClass( 'd-none' );
		}
        if( response.shipping_cost_converted ){
            this.$totalDiscount.html( window.renderPrice( response.discountAmountNoShipCostDiscount_converted ) + ' (' + window.renderPrice( response.discountAmountNoShipCostDiscount, null, window.ZG_CONFIG.original_currency ) + ')' );
        }
        else{
            this.$totalDiscount.html( window.renderPrice( response.discountAmountNoShipCostDiscount ) );
        }

		if ( !response.appliedPromotion || response.appliedPromotion.length === 0 ) {
			this.$appliedPromotionContainer.addClass( 'd-none' );
		} else {
			this.$appliedPromotionContainer.removeClass( 'd-none' );
		}

		this.$appliedPromotion.empty();
		_.each( response.appliedPromotion, function ( promo ) {
			this.$appliedPromotion.append( '<div>'  + promo + '</div>' );
		}, this );

        if( response.shipping_cost_converted ){
            this.$totalTax.html( window.renderPrice( response.taxTotal_converted ) + ' (' + window.renderPrice( response.taxTotal, null, window.ZG_CONFIG.original_currency ) + ')' );
            this.$totalSumConverted.html( window.renderPrice( response.total_sum_converted ) );
            this.$totalSum.html( window.renderPrice( response.total_sum, null, window.ZG_CONFIG.original_currency ) );
        }
        else{
            this.$totalTax.html( window.renderPrice( response.taxTotal, false, response.currency_converted ) );
            this.$totalSum.html( window.renderPrice( response.total_sum, false, response.currency_converted ) );
        }
	};


	/**
	 * @method __setEventHandlers
	 * @listen document#change.zg.selectSipping On radio checked change, call the ajax function
	 */

	SelectShipping.prototype.__setEventHandlers = function () {
		this.$element.on( 'change.zg.selectSipping', this.options.selector, (function ( e ) {
			var $target = $( e.target );

			this.request( $target.val(), $target.data( 'shipping-info' ) );
		}).bind( this ) );
	};


	// SELECT SHIPPING PLUGIN DEFINITION
	// =================================

	function Plugin ( option ) {
		return this.each( function () {
			var $this   = $( this );
			var data    = $this.data( 'zg.selectShipping' );
			var options = _.extend( {}, ZG_CONFIG || {}, $this.data(), typeof option === 'object' && option );

			if ( !data ) {
				$this.data( 'zg.selectShipping', new SelectShipping( this, options ) );
			}
		} );
	}

	$.fn.zgSelectShipping             = Plugin;
	$.fn.zgSelectShipping.Constructor = SelectShipping;


	// SELECT SHIPPING DATA-API
	// ========================

	$( function () {
		$( selector ).each( function () {
			Plugin.call( $( this ) );
		} );
	} );


}( jQuery, _ ));
