/**
 * zgCalculateDistance
 *
 *   Calculate distance between to places based on their coordinates.
 *   Uses the 'haversine' formula
 *
 *
 * @author: David Pocina <dpocina[at]zerogrey[dot]com>
 *
 */

(function () { /* global _ */
	'use strict';

	// Establish the root object (`window` in the browser)
	var root = this;

	// === Math.PI / 180
	var deg2rad = 0.017453292519943295;

	// Diameter of the earth (in km)
	var diam = 12742;


	/**
	 *
	 * @param {Object} a
	 * @param {Object} b
	 * @returns {null|number}
	 */
	var calculateDistance = function ( a, b ) {
		var distance;

		if ( __validate( a ) && __validate( b ) ) {
			distance = __getDistance( (+a.lat * deg2rad), (+b.lat * deg2rad), (+a.lng * deg2rad), (+b.lng * deg2rad) );
		}

		return __format( distance );
	};


	/**
	 * round the result to 2 decimals
	 *
	 * @param {number=} distance
	 * @returns {number|null}
	 * @private
	 */
	var __format = function ( distance ) {
		var result = null;

		if ( _.isNumber( distance ) ) {
			result = Math.round( distance * 100 ) / 100;
		}

		return result;
	};


	/**
	 * Calculate the distance in km using the 'haversine' formula
	 *
	 * @param {number} radLatA
	 * @param {number} radLatB
	 * @param {number} radLngA
	 * @param {number} radLngB
	 * @returns {number}
	 * @private
	 */
	var __getDistance = function ( radLatA, radLatB, radLngA, radLngB ) {
		var distance = (
				(1 - Math.cos( radLatB - radLatA )) +
				(1 - Math.cos( radLngB - radLngA )) * Math.cos( radLatA ) * Math.cos( radLatB )
			) / 2;

		return (diam * Math.asin( Math.sqrt( distance ) ));
	};


	/**
	 *
	 * @param {Object} obj
	 * @returns {boolean}
	 * @private
	 */
	var __validate = function ( obj ) {
		var valid = false;

		if ( obj && !isNaN( obj.lat ) && !isNaN( obj.lng ) ) {
			valid = true;
		}

		return valid;
	};


	// -----------------------------------------------------------------------------------------------------------------

	root.zgStoreLocatorCalculateDistance = calculateDistance;

}.call( this ));
