/**
 *
 * Add multiple products to the cart in one action.
 *
 * @author: Pietro Vignola <pvignola[at]kooomo[dot]com>
 *
 */

/**
 * @event document#click.zg.shopByLook Click on the plugin selector button
 * @type {null}
 */

/* global _, zgPost, JS_TRANSLATIONS */

( function ( $ ) {
    'use strict';

    /**
     * @selector data-zg-role="sbl-buy-all" The plugin start if there is the selector (button) in the dom when the page load
     */
    var selector = '[data-zg-role="sbl-buy-all"]';

    // SHOPBYLOOK CLASS DEFINITION
    // ===========================

    var ShopByLook = function ( element, options ) {
        this.$element = $( element );
        this.options = options;
    };

    /**
     * @param {string} [target] Container of products list linked
     * @param {string} [product] Container of main product
     */
    ShopByLook.DEFAULTS = {
        target:  '[data-zg-role="sbl-items"]',
        product: '[data-zg-role="product"]'
    };

    /**
     * Get product for this container SBL target
     */
    ShopByLook.prototype.getProducts = function () {

        var $target = $(document).find( this.options.target ),
            data    = $target.data();

        var $products   = $target.find( this.options.product );


        if( data.sblCheckbox ){
            $products = $products.filter( function () {
                var isValid = false;

                if ( $( this).find( '[name="' + data.sblCheckbox + '"]:checked').length ) {
                    isValid = true;
                }

                return isValid;
            } );

            if( !$products.length ) {
                $(document).trigger('zg-error', [{
                    message: window.JS_TRANSLATIONS['please_select'] || 'Please select at least a product.'
                }]);
            }
        }

        if( $products.length > 0 ) {
            this.checkProducts($products);
        }
    };

    /**
     * Check if products are available and have sku selected
     */
    ShopByLook.prototype.checkProducts = function ( $products ) {

        if( !$products.length ){
            return false;
        }

        var multivalues         = [],
            validProducts       = [],
            notAvailable        = [],
            notOptionsSelected  = [];

        $products.each( _.bind( function ( key, element ) {
            var zgProduct = $(element).data('zg.product');

            if( zgProduct && zgProduct.selectedSku && (zgProduct.selectedSku !== 0) ){
                if( zgProduct.isAvailable ){
                    multivalues[ zgProduct.product.id ] = zgProduct;
                    validProducts.push( multivalues[ zgProduct.product.id ] );
                }
                else if ( zgProduct.availabilityChecked ) {
                    notAvailable.push( zgProduct.product.name );
                }
            }
            else {
                notOptionsSelected.push( zgProduct.product.name );
            }
        }, this ) );

        this.multiAddToCartRequest( validProducts );
        this.showNotification( 'following.products.not.available', notAvailable );
        this.showNotification( 'following.products.have.no.options.selected', notOptionsSelected );

        return true;
    };

    /**
     * @method multiAddToCartRequest
     */

    ShopByLook.prototype.multiAddToCartRequest = function ( validProducts ) {

        if( !validProducts.length ){
            return false;
        }

        var callback, data = {
            items: []
        };

        _.each( validProducts || [], function ( element ) {
            var addToCart;

            if ( element.product && element.options.enableAddToCart ) {
                addToCart = element.options.enableAddToCart;

                // Add to the cart
                if ( addToCart ) {
                    data.items.push({
                        product_id: element.product.id,
                        sku:        element.selectedSku,
                        quantity:   element.cleanQuantity() || 1
                    });
                }
            }
        });

        if( data.items.length ){
            callback = {
                success: _.bind( function ( data ) {
                    $( document ).trigger( 'zg.product.addedToCart', [data, validProducts] );
                }, data ),
                error: function () {
                    // Something went wrong. Request the cart items again.
                    $( document ).trigger( 'zg.getProductList.request-info', ['cart'] );
                }
            };

            zgPost(
                'addProductToCart',
                data,
                {
                    success: window.JS_TRANSLATIONS['add.to.cart.multiple']
                },
                callback
            );
        }
    };

    /**
     * Show notification with subject message
     */
    ShopByLook.prototype.showNotification = function ( msg, subject ) {
        if( !subject.length ){
            return false;
        }

        $(document).trigger('zg-error', [{
            message: window.JS_TRANSLATIONS[msg] + ' ' + subject.toString()
        }]);
    };


    // SHOPBYLOOK PLUGIN DEFINITION
    // ============================

    var Plugin = function ( option ) {
        return this.each( function () {

            var $this  = $( this );
            var data   = $this.data( 'zg.shopByLook' );
            var options = $.extend( {}, ShopByLook.DEFAULTS, window.ZG_CONFIG || {}, $this.data(), typeof option === 'object' && option );

            if ( !data ) {
                $this.data( 'zg.shopByLook', ( data = new ShopByLook( this, options ) ) );
            }

            data.getProducts();

        });
    };

    $.fn.zgShopByLook = Plugin;
    $.fn.zgShopByLook.Constructor = ShopByLook;


    /**
     * @method document
     * @listen document#click.zg.shopByLook On click of selector the plugin start
     */

    $( function () {
        $( document ).on( 'click.zg.shopByLook', selector, function () {
            Plugin.call( $( this ) );
        } );
    } );

} ( jQuery ) );
