/**
 * Plugin for split an order and send only in stock part
 *
 * @author: Pietro Vignola  <pvignola[at]kooomo[dot]com>
 * @author: David Pocina  <dpocina[at]kooomo[dot]com>
 *
 */

/**
 * @event document#zg-error Generic error. Used by 2002-zg-notifier.js to display the error
 * @type {object}
 * @property {string} eventType - Typology of event error.
 * @property {string} message - The error message to be translated.
 */

(function ( $, _ ) { /* global _, JS_TRANSLATIONS, zgPost */
	'use strict';

	/**
	 * @selector data-zg-role="partially-shippable" The plugin start if there is the selector (button) in the dom when the page load
	 */

	var selector = '[data-zg-role="partially-shippable"]',
		$partiallyShippable = $( selector );


	/**
	 *
	 * @param {string}        action
	 * @param {string|number} orderId
	 */

	/**
	 * @method partiallyShippable
	 * @fires document#zg-error Display error if there isn't order id
	 *
	 */

	var partiallyShippable = function ( action, orderId ) {
		var callback;

		if ( action && orderId ) {

			$partiallyShippable.attr( 'disabled', true );

			callback = {
				success: _.partial( function ( orderId ) {
					// go to the order view page
					location.assign( window.makeUrl( {
						module:  'eshop',
						manager: 'order',
						action:  'view',
						'oID':   orderId
					} ) );
				}, orderId ),

				error: _.partial( function ( $partiallyShippable ) {
					// re-enable the buttons
					$partiallyShippable.attr( 'disabled', false );
				}, $partiallyShippable )
			};

			zgPost( action, { order_id: orderId }, null, callback );

		} else {

			$( document ).trigger(
				'zg-error',
				[{
					eventType: 'zgPartiallyShippable - ' + action,
					message:   JS_TRANSLATIONS.genericErrorMsg
				}]
			);

		}
	};


	//cancelOrder shipOrder
	$partiallyShippable.on( 'click.zg.partiallyShippable', function ( e ) {
		var data;

		e.preventDefault();

		data = $( this ).data();

		partiallyShippable( data.action, data.orderId );
	} );

}( jQuery, _ ));
