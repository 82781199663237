/**
 * Plugin for create a user marker manager (set current position or search specific address)
 *
 *    Create a table with all the stores with missing geolocation.
 *    Creates a csv file to be imported in the CMS.
 *
 *
 * @author: David Pocina <dpocina[at]zerogrey[dot]com>
 *
 */

/**
 * @event document#zg-error Generic error. Used by 2002-zg-notifier.js to display the error
 * @type {object}
 * @property {string} eventType - Typology of event error.
 * @property {string} message - The error message to be translated.
 */

/**
 * @event document#click.zg.storeLocator Click on button
 * @type {null}
 */

/**
 * @event document#change.zg.storeLocator Input change value
 * @type {null}
 */

(function ( $, _ ) {
	/* global _, DEBUG, Promise, google, handlebarsTemplates, loadGoogleMaps, JS_TRANSLATIONS, SGL_JS_ISLOGGED */
	'use strict';

	// Establish the root object (`window` in the browser)
	var root = this;

	/**
	 * @selector data-zg-role="sl-user-marker-container" The plugin start if there is the selector in the dom when the page load
	 */
	var selector = '[data-zg-role="sl-user-marker-container"]';

	/**
	 * @param {string} [sLocatorSelector] Store locator container
	 * @param {string} [sLocatorDataAttr] Data attribute of events of store locator
	 * @param {string} [userMarkerDialogTemplate] Handlebars template of dialog window for the user (with button for geo localization or search an address)
	 * @param {string} [userMarkerSelector] Selector for handlers events
	 * @param {string} [userMarkerIcon] image to use as the user marker
	 * @param {string} [userMarkerAddressContainer] Container of address list (if user is logged)
	 * @param {string} [userMarkerAddressInput] Select with user address
	 * @param {string} [userMarkerSearchInput] Input text for search an address in the map
	 */

	var DEFAULTS = {
		sLocatorSelector: '[data-zg-role="store-locator"]',
		sLocatorDataAttr: 'zg.storeLocator',

		userMarkerDialogTemplate: 'storelocator-user-marker-dialog',
		userMarkerSelector:       '[data-zg-role="sl-show-user-marker"]',

		userMarkerIcon: '/themes/178/Skeleton/images/storeLocator/userMarker.png',

		userMarkerAddressContainer: '[data-zg-role="address-list"]',
		userMarkerAddressInput:     '[data-zg-role="sl-user-marker-address-input"]',
		userMarkerSearchInput:      '[data-zg-role="sl-user-marker-search-input"]'
	};


	// CLASS DEFINITION
	// ================

	var SetUserMarker = function ( element, options ) {
		this.$element = $( element );
		this.options  = options;

		this.storeLocator = $( options.sLocatorSelector ).data( options.sLocatorDataAttr );

		loadGoogleMaps().then( (function () {
			this.geocoder = new google.maps.Geocoder();

			/** @type {null|google.maps.Marker} */
			this.marker = null;

			/** @type {null|number|string} */
			this.positionWatcherId = null;

			this.userPosition = null;

			/** @type {null|string} */
			this.type = null;

			this.__init();
			this.__setEventHandlers();
		}).bind(this) );

	};


	SetUserMarker.prototype.getUserPosition = function () {
		return this.userPosition;
	};


	/**
	 *
	 */
	SetUserMarker.prototype.hide = function () {
		if ( this.marker ) {
			this.marker.setMap( null );
		}
	};


	/**
	 *
	 */
	SetUserMarker.prototype.show = function () {
		if ( this.marker && this.storeLocator && this.storeLocator.map ) {
			this.marker.setMap( this.storeLocator.map );
		}
	};

	// -----------------------------------------------------------------------------------------------------------------

	/**
	 * destroy the previous positionWatcher
	 *
	 * @private
	 */
	SetUserMarker.prototype.__destroyPositionWatcher = function () {
		if ( !_.isNull( this.positionWatcherId ) ) {
			navigator.geolocation.clearWatch( this.positionWatcherId );
			this.positionWatcherId = null;
		}
	};


	/**
	 *
	 * @returns {string}
	 * @private
	 */
	SetUserMarker.prototype.__getAddressFromSelect = function () {
		var $select = $( this.options.userMarkerAddressInput );

		return $select.find( 'option[value="' + $select.val() + '"]' ).text();
	};


	/**
	 *
	 * @private
	 */
	SetUserMarker.prototype.__init = function () {
		var data = {
			geolocation: ( 'geolocation' in navigator ),
			address:     SGL_JS_ISLOGGED,
			search:      true
		};

		this.$element
			.html(handlebarsTemplates.render(this.options.userMarkerDialogTemplate, data))
			.removeClass('hidden');


		if (google.maps.places) {
			this.$element.find(this.options.userMarkerSearchInput).each(function (index, item) {
				var autocomplete = new google.maps.places.Autocomplete(
					item,
					{
						types: ['geocode']
					}
				);

				autocomplete.addListener('place_changed', function () {
					$(item).trigger('change.zg.storeLocator');
				});
			});

			this.autoComplete = new google.maps.places.AutocompleteService();
		}

		// request user addresses
		if ( data.address ) {
			this.$element
				.find( this.options.userMarkerAddressContainer )
				.zgAddressList();
		}
	};


	/**
	 *
	 * @param {string} address
	 * @private
	 */

	/**
	 * @method __setByAddress
	 * @fires document#zg-error Display the error if the google map is not init or geodecoder not return status ok
	 */

	SetUserMarker.prototype.__setByAddress = function ( address ) {
		if ( _.isString( address ) ) {
			this.geocoder.geocode( {address: address}, (function ( results, status ) {
				if ( status === google.maps.GeocoderStatus.OK ) {
					this.storeLocator.centerMap.byLocationObject( results[0].geometry.location );
					this.__setPosition( results[0] );
				} else {
					$( document ).trigger( 'zg-error', [{
						message: JS_TRANSLATIONS['storeLocator.error.invalidAddress'] + '<br>' + address
					}] );
				}
			}).bind( this ) );
		} else if ( DEBUG ) {
			$( document ).trigger( 'zg-error', [{
				message: JS_TRANSLATIONS['storeLocator.error.invalidAddress'] + '<br>' + address
			}] );
		}
	};


	/**
	 *
	 * @private
	 */

	/**
	 * @method __setByGeolocation
	 * @fires document#zg-error Display the error if ajax call for geodecoder make an error status
	 */

	SetUserMarker.prototype.__setByGeolocation = function () {
		var center;
		var geolocationPromise;
		var location;

		if ( 'geolocation' in navigator ) {
			center = true;

			this.__destroyPositionWatcher();

			geolocationPromise = new Promise( function ( resolve, reject ) {
				navigator.geolocation.getCurrentPosition(
					resolve,
					reject
				);
			} );

			geolocationPromise.then(
				(function ( position ) {
					if ( this.type === 'geolocation' ) {
						if ( position && position.coords ) {
							location = new google.maps.LatLng( position.coords.latitude, position.coords.longitude );

							// update map position
							if ( center ) {
								center = false;
								this.storeLocator.centerMap.byLocationObject( location );
							}

							this.geocoder.geocode(
								{location: location},
								(function ( results, status ) {
									if ( status === google.maps.GeocoderStatus.OK ) {
										this.__setPosition( results[0] );
									} else {
										$( document ).trigger( 'zg-error', [{
												message: JS_TRANSLATIONS.genericErrorMsg
											}]
										);
									}
								}).bind( this )
							);
						}
					}
				}).bind( this ),

				function ( error ) {
					var msg = error && error.message ? '<br>' + error.message : '';

					$( document ).trigger( 'zg-error', [{
						message: JS_TRANSLATIONS['storeLocator.error.geolocationRequired'] + msg
					}] );
				}
			);
		} else {
			$( document ).trigger( 'zg-error', [{
				message: JS_TRANSLATIONS['storeLocator.error.geolocationRequired']
			}] );
		}
	};

	/**
	 * @method __setEventHandlers
	 *
	 * @listen document#click.zg.storeLocator  On click set the markers near of selected address or address searched
	 * @listen document#change.zg.storeLocator On change set the markers near of selected address or address searched
	 *
	 *
	 * @private
	 */
	SetUserMarker.prototype.__setEventHandlers = function () {
		var that = this;

		// create user marker
		// ---------------------------------------------------------------------

		this.$element.on( 'click.zg.storeLocator', this.options.userMarkerSelector, function () {
			that.__setup( $( this ).data( 'value' ) );
		} );

		// address selector
		// ---------------------------------------------------------------------

		this.$element.on( 'change.zg.storeLocator', this.options.userMarkerAddressInput, function () {
			that.__setType( 'address' );
			that.__setByAddress( $( this ).find( 'option:selected' ).text() );
		} );

		// search field
		// ---------------------------------------------------------------------

		this.$element.on( 'change.zg.storeLocator', this.options.userMarkerSearchInput, _.debounce( function () {
			that.__setType( 'search' );
			that.__setByAddress( $( this ).val() );
		}, 300) );
	};


	/**
	 *
	 * @param {Object=} position
	 * @private
	 */
	SetUserMarker.prototype.__setPosition = function ( position ) {
		var markerData;
		var location;

		if ( position ) {
			location = position.geometry.location;

			this.userPosition = location;

			// update the stores distance info
			this.storeLocator.calculateStoresDistance( {lat: location.lat(), lng: location.lng()} );

			// setup the marker
			if ( this.storeLocator.map ) {

				if ( this.marker ) {

					this.marker.setMap( this.storeLocator.map );
					this.marker.setPosition( location );

				} else {
					// create marker options
					markerData = {
						map:         this.storeLocator.map,
						position:    location,
						zoomOnClick: true,
						flat:        true//,
						//title:       "",
					};

					if ( this.options.userMarkerIcon ) {
						markerData.icon = this.options.userMarkerIcon;
					}

					this.marker = new google.maps.Marker( markerData );
				}

			} else if ( DEBUG ) {
				console.warn( 'StoreLocator.userMarker.__setPosition - FAILED' );
			}
		} else {
			this.userPosition = null;

			// reset the stores distance information
			this.storeLocator.calculateStoresDistance( null );
		}

		$( document ).trigger( 'zg.storeLocator.userMarker', position );
	};


	/**
	 *
	 * @param {!string} type
	 * @private
	 */
	SetUserMarker.prototype.__setup = function ( type ) {
		var address;

		switch ( type ) {
			case 'geolocation':
				this.__setType( type );

				this.__setByGeolocation();

				break;

			case 'address':
				this.__setType( type );

				address = this.__getAddressFromSelect();
				this.__setByAddress( address );

				break;

			case 'search':
				this.__setType( type );

				address = $( this.options.userMarkerSearchInput ).val();
				this.__setByAddress( address );

				break;

			case 'reset':
				this.__setType( null );
				this.__setPosition( null );
				this.hide();
				break;

			default:
				$( document ).trigger(
					'zg-error', [{
						message: JS_TRANSLATIONS.genericErrorMsg
					}]
				);
				break;
		}
	};


	/**
	 *
	 * @param {string=} type
	 * @private
	 */
	SetUserMarker.prototype.__setType = function ( type ) {
		this.type = type;

		$( this.options.userMarkerSelector )
			.removeClass( 'active' )
			.filter( '[data-value="' + type + '"]' )
			.addClass( 'active' );

		// destroy the positionWatcher
		if ( type !== 'geolocation' ) {
			this.__destroyPositionWatcher();
		}
	};


	// STORE LOCATOR PLUGIN DEFINITION
	// ===============================

	function Plugin ( option ) {
		return this.each( function () {
			var $this   = $( this );
			var data    = $this.data( 'zg.setUserMarker' );
			var options = $.extend( {}, DEFAULTS, root.ZG_CONFIG || {}, $this.data(), typeof option === 'object' && option );

			if ( !data ) {
				$this.data( 'zg.setUserMarker', ( data = new SetUserMarker( this, options ) ) );
			} else if ( option ) {
				data.__setOptions( options );
			}
		} );
	}

	$.fn.SetUserMarker             = Plugin;
	$.fn.SetUserMarker.Constructor = SetUserMarker;


	// STORE LOCATOR DATA-API
	// ======================

	$( function () {
		$( selector ).each( function () {
			Plugin.call( $( this ) );
		} );
	} );


	// -----------------------------------------------------------------------------------------------------------------

	root.ZgStoreLocatorSetUserMarker = SetUserMarker;

}.call( this, jQuery, _ ));
