/**
 *
 * Sends an email to with the current url
 *
 * @author Pietro Vignola <pvignola[at]kooomo[dot]com>
 */

(function ( $ ) { /* global JS_TRANSLATIONS, zgPost */
	'use strict';

	/**
	 * @selector data-zg-role="send-to-friend" The plugin start on click to selector
	 */
    var SELECTOR = '[data-zg-role="send-to-friend"]';

	// SENDTOFRIEND CLASS DEFINITION
	// =============================

	var SendToFriend = function ( element, options ) {
        this.$element = $( element );
		this.options  = options;
	};

    /**
     * Function for serializing the form inputs.
     * The filtering process is for removing the fields added for the Google captcha
     * @private
     */
    SendToFriend.prototype.__getFormData = function () {
        return this.$element.serializeArray();
    };


	/**
	 *
	 */
	SendToFriend.prototype.send = function () {
		if (
			this.$element.data('zgValidator') &&
			!this.$element.data('zgValidator').validate()
		) {
			return false;
		}

        //var formData = new FormData(this.$element.get(0));
        var data = this.__getFormData();

        zgPost(
            'sendProductByEmailTo',
            data,
            {
                error: JS_TRANSLATIONS.genericErrorMsg
            },
            {
                success: _.bind(function (response) {
                    if ( response.status === 'success' ) {
                        this.$element.find( 'input[type!="hidden"]' ).val('');
                    } else  {
                        $( document ).trigger( 'zg-error', [{
                            eventType: 'SendToFriend',
                            message:   (response && response.message ? response.message : JS_TRANSLATIONS.genericErrorMsg)
                        }] );
                    }
                }, this)
            }
        );
	};


	// SENDTOFRIEND PLUGIN DEFINITION
	// ==============================

	function Plugin( option ) {
		return this.each(function () {
			var $this  = $(this);
			var data   = $this.data('zg.sendToFriend');
			var options = $.extend({}, SendToFriend.DEFAULTS, window.ZG_CONFIG || {}, $this.data(), typeof option === 'object' && option);

			if (!data) {
				$this.data('zg.sendToFriend', (data = new SendToFriend(this, options)));
			}

			data.send();
		});
	}

	$.fn.zg_sendToFriend             = Plugin;
	$.fn.zg_sendToFriend.Constructor = SendToFriend;


	/**
	 * @method document
	 * @listen submit On submit of SELECTOR start the plugin
	 */

    $( document ).on( 'submit', SELECTOR, function ( e ) {
        e.preventDefault();
        Plugin.call($(this));
    } );

})( jQuery );