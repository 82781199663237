/* globals Promise */

(function () {
	'use strict';

	// Establish the root object ('window' in the browser)
	var root = this;

	var googleMapsPromise;

	/**
	 *
	 * @returns {boolean}
	 */
	var isGoogleMapsAvailable = function isGoogleMapsAvailable () {
		return !!( root.google && root.google.maps && root.google.maps.Geocoder );
	};

	root.isGoogleMapsAvailable = isGoogleMapsAvailable;

	/**
	 *
	 * @returns {Promise}
	 */
	var loadGoogleMaps = function loadGoogleMaps () {
		if ( !googleMapsPromise ) {
			googleMapsPromise = new Promise( function ( resolve, reject ) {
				if ( isGoogleMapsAvailable() ) {
					resolve();
				} else {
					$.ajax( {
						url:      'https://maps.google.com/maps/api/js',
						dataType: 'script',
						data:     {
							key: root.ZG_CONFIG.googleMapsApiKey,
							libraries: 'places'
						},
						cache:    false, // always load latest version
						success:  resolve,
						error:    function () {
							reject( new Error( 'Google Maps API missing' ) );
						}
					} );
				}
			} );
		}

		return googleMapsPromise;
	};

	root.loadGoogleMaps = loadGoogleMaps;

}.call( this ));
