/**
 * Function for login with facebook
 *
 * @author: Botond Szasz <bszasz@zerogrey.com>
 * @author: G K <gkasa@zerogrey.com>
 *
 * @author: David Pocina <dpocina[at]kooomo[dot]com>
 *          This code is really weird.
 *          I removed the craziest parts and the things we were not using anymore...
 */

/* globals Promise, FB, ZG_CONFIG */

(function ( $ ) {
	'use strict';

	// FACEBOOK SDK
	if ( window.ZG_CONFIG && ZG_CONFIG.fbAppId ) {
		window.fbAsyncInit = function () {
			return new Promise( function ( resolve ) {
				FB.init( {
					appId:   ZG_CONFIG.fbAppId, // App ID
					version: 'v3.0',
					status:  true, // check login status
					xfbml:   true,  // parse XFBML
					cookie:  true
				} );

				resolve();
			} ).then(
				function () {
					var keepAlive = true;
					var keepAliveTimer;

					// var forceLogout = window.makeUrl( {
					// 	module:  'user',
					// 	manager: 'login',
					// 	action:  'logout'
					// } );

					var clearIntervalFB = function clearIntervalFB () {
						if ( keepAliveTimer !== void 0 ) {
							window.clearInterval( keepAliveTimer );
						}
					};

					var checkStatusFB = function checkStatusFB () {
						FB.getLoginStatus( function ( response ) {
							if ( response.status === 'connected' ) {
								$.ajax( {
									type: 'POST',
									url: window.makeUrl( {
										module:  'eshop',
										manager: 'eshop',
										action:  'checkStatusSN'
									} ),
									data: {
										sn_type: 'facebook'
									},
									dataType: 'json',
									success: function () {
										if ( response.result === 'failure' ) {
											clearIntervalFB();
											keepAlive = false;
										}
									}
								} );
							} else {
								clearIntervalFB();
							}
						}, true );
					};

					FB.Event.subscribe( 'auth.statusChange', function ( response ) {
						if ( response.status === 'connected' && window.SGL_JS_ISLOGGED && keepAlive ) {
							if ( keepAliveTimer === void 0 ) {
								keepAliveTimer = setInterval( checkStatusFB, 200000 );
							}
						} else {
							clearIntervalFB();
						}
					} );


					$( '[data-zg-role="fb-login"]' ).on( 'click', function () {
						FB.login( function ( response ) {
							var $fbLoginForm;
							var redirectTo;

							if ( response.status === 'connected' ) {
								redirectTo = $( '#login-redir' ).val() || document.location.toString();

								$fbLoginForm = $( '<form />' ).attr( {
									name:   'fbLogin',
									action: window.makeUrl( {module: 'eshop', manager: 'login'} ),
									method: 'post'
								} );

								$fbLoginForm.append( '<input type="hidden" name="sn_login" value="1" />' );
								$fbLoginForm.append( '<input type="hidden" name="sn_type" value="facebook" />' );
								$fbLoginForm.append( '<input type="hidden" name="redir" value="' + redirectTo + '" />' );

								$( 'body' ).append( $fbLoginForm );
								$fbLoginForm.submit();
							}
						}, {
							scope: 'email,user_birthday',
                            return_scopes: true
						} );
					} );
				}
			);
		};


		// Load the SDK Asynchronously
		(function () {
			var js;
			var id = 'facebook-jssdk';

			if ( !document.getElementById( id ) ) {
				js       = document.createElement( 'script' );
				js.id    = id;
				js.async = true;
				js.src   = '//connect.facebook.net/en_US/sdk.js';

				$( 'body' ).append( js );
			}
		}());
	}
})( jQuery );
