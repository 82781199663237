/**
 * Create google map with filted store markers
 *
 *
 * @author: David Pocina <dpocina[at]zerogrey[dot]com>
 *
 */

/* global _, Promise, google, isGoogleMapsAvailable, ZgExportMissingGeolocation */

(function ( _ ) {
	'use strict';

	// Establish the root object ('window' in the browser)
	var root = this;

	/**
	 * @typedef DEFAULTS
	 * @type {object}
	 * @property {boolean} [exportMissingGeolocation]   export missing geolocation info. Only enable this for testing,
	 * @property {number}  [geolocationTimer]           ms between geolocation requests if the shop coordinates are not
	 *                                                  available
	 * @property {number}  [geolocationTimerIncrement]  The more requests we do the longer we have to wait to request
	 *                                                  more info, or google will just stop answering.
	 * @property {number}  [geolocationMaxIterations]   Maximun number of times the geolocation will be requested for a
	 *                                                  specific store
	 */
	/** @type {DEFAULTS} */
	var DEFAULTS = {
		exportMissingGeolocation:  false,
		geolocationTimer:          500,
		geolocationTimerIncrement: 2.5,
		geolocationMaxIterations:  10
	};


	// CLASS DEFINITION
	// ================

	/**
	 *
	 * @param {Object} options
	 * @constructor
	 */
	var GeolocationMgr = function ( options, items ) {
		this.options = _.extend( {}, DEFAULTS, root.ZG_CONFIG, options || {} );

		this.items    = items || {};
		this.requests = 0;

		if ( !isGoogleMapsAvailable() ) {
			throw new Error( 'StoreLocator.mapMarkersMgr - FAILED - Google maps is not available' );
		}

		this.geocoder = new google.maps.Geocoder();

		this.geolocationPromises = {};

		this.MissingGeolocationMgr = new ZgExportMissingGeolocation( this.options );
	};


	/**
	 *
	 * @param {Object} [items]
	 */
	GeolocationMgr.prototype.setItems = function ( items ) {
		this.items = items || {};
	};


	/**
	 *
	 */
	GeolocationMgr.prototype.request = function ( itemId, timer, force ) {
		var address;
		var currentTimer;

		if ( !this.geolocationPromises[itemId] || force ) {
			this.geolocationPromises[itemId] = new Promise( (function ( resolve, reject ) {
				if ( itemId && this.items[itemId] ) {
					if ( !isNaN( +this.items[itemId].fields.Latitude ) && !isNaN( +this.items[itemId].fields.Longitude ) ) {
						resolve( itemId );
					} else {
						address      = this.__getAddressFromStore( itemId );
						currentTimer = timer || ( this.options.geolocationTimer * this.requests );

						this.__getItemLocation( itemId, address, currentTimer, 0 ).then(
							(function ( response ) {
								this.__updateItemLocation( itemId, response.results, response.status );
								resolve( response );
							}).bind( this ),

							reject
						);
					}
				} else {
					reject( new Error( 'StoreLocator.GeolocationMgr.request - FAILED - unknown item ' + itemId ) );
				}
			}).bind( this ) );
		}

		return this.geolocationPromises[itemId];
	};


	// ------------------------------------------------------------------------
	// PRIVATE METHODS

	/**
	 *
	 * @param {string} itemId
	 * @returns {null|string}
	 * @private
	 */
	GeolocationMgr.prototype.__getAddressFromStore = function ( itemId ) {
		var address = null;

		if ( this.items && this.items[itemId] ) {
			address = '' +
				this.items[itemId].fields.Country + ', ' +
				this.items[itemId].fields.Province + ', ' +
				this.items[itemId].fields.City + ', ' +
				this.items[itemId].fields.Street;
		}

		return address;
	};

	/**
	 *
	 * @param {string} itemId
	 * @param {string} address
	 * @param {number} [timer]
	 * @param {number} [iteration]
	 * @returns {Promise}
	 * @private
	 */
	GeolocationMgr.prototype.__getItemLocation = function ( itemId, address, timer, iteration ) {
		return new Promise( (function ( resolve, reject ) {
			setTimeout(
				(function () {
					this.__parseItemLocation( itemId, address, iteration ).then( resolve, reject );
				}).bind( this ),

				timer || 0
			);
		}).bind( this ) );
	};


	/**
	 *
	 * @param itemId
	 * @param address
	 * @param iteration
	 * @returns {Promise}
	 * @private
	 */
	GeolocationMgr.prototype.__parseItemLocation = function ( itemId, address, iteration ) {
		return new Promise( (function ( resolve, reject ) {
			this.geocoder.geocode(
				{address: address},

				(function ( results, status ) {
					var timer;

					if (
						(
							status === google.maps.GeocoderStatus.OVER_QUERY_LIMIT ||
							status === google.maps.GeocoderStatus.UNKNOWN_ERROR
						) &&
						iteration < this.options.geolocationMaxIterations
					) {
						// request again and bubble the response up :)
						timer = this.options.geolocationTimer * this.options.geolocationTimerIncrement;
						iteration++;

						this.__getItemLocation( itemId, timer, iteration ).then( resolve, reject );
					} else {
						resolve( {
							results: results,
							status:  status
						} );
					}

				}).bind( this )
			);
		}).bind( this ) );
	};


	GeolocationMgr.prototype.__updateItemLocation = function ( itemId, results, status ) {
		if ( status === google.maps.GeocoderStatus.OK ) {
			this.items[itemId].fields.Latitude  = results[0].geometry.location.lat();
			this.items[itemId].fields.Longitude = results[0].geometry.location.lng();
		}

		// Export geolocation
		if ( this.options.exportMissingGeolocation ) {
			this.MissingGeolocationMgr.addStore( itemId, this.items[itemId], status );
		}
	};


	// -----------------------------------------------------------------------------------------------------------------

	root.ZgStoreLocatorGeolocationMgr = GeolocationMgr;

}.call( this, _ ));
