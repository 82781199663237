(function ( $, _ ) {
	'use strict';

	// Establish the root object ('window' in the browser)
	var root = this;

	/**
	 *
	 * @param {string} text
	 * @returns {string}
	 */
	root.nl2br = function nl2br ( text ) {
		return (text + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1' + '<br>' + '$2');
	};

}.call( this, jQuery, _ ));