/**
 * zg-change-password.js
 *
 * Plugin for change password in ajax call
 * We use this plugin in account page
 *
 * @author David Pocina  <dpocina[at]kooomo[dot]com>
 *
 */


(function ( $, _ ) { /* global _, DEBUG, ZG_CONFIG, zgPost */
    'use strict';

    /**
     * @selector data-zg-role="change-password" The plugin start if there is the selector in the dom when the page load. The selecto should be in the form
     */
    var selector = '[data-zg-role="change-password"]';

    // CHANGE PASSWORD CLASS DEFINITION
    // ================================

    /**
     *
     * @param {HTMLElement} element
     * @param {!Object}     options
     *
     * @constructor
     */
    var ChangePassword = function ( element, options ) {
        this.$element = $( element );

        this.options = _.clone( ChangePassword.DEFAULTS );
        this.__setOptions( options );

        this.__setEventHandlers();
    };


    /**
     * @param {boolean} [confirmPassword] We don't want the user to type the new password twice (we have the unmask password thingy).
     */
    ChangePassword.DEFAULTS = {
        confirmPassword: true
    };


    ChangePassword.prototype.submit = function () {
        var i, formData, request;

        if ( !this.$element.data( 'zgValidator' ) || this.$element.data( 'zgValidator' ).validate() ) {
            formData = this.$element.serializeArray();
            request  = {};

            for ( i = 0; i < formData.length; i++ ) {
                request[( formData[i].name )] = formData[i].value;
            }

            if ( !this.options.confirmPassword ) {
                // We don't want the user to type the new password twice (we have the unmask password thingy).
                // We just copy the new_password field.
                request.confirm_password = request.new_password;
            }

            if ( DEBUG ) {
                console.info( 'changeUserPassword', request );
            }

            zgPost( 'changeUserPassword', request );
        }
    };


    // CHANGE PASSWORD PRIVATE METHODS
    // ===============================

    /**
     *
     * @private
     */
    ChangePassword.prototype.__setEventHandlers = function () {
        var that = this;

        this.$element.on( 'submit.zg.changePassword', function ( e ) {
            e.preventDefault();

            that.submit();
        } );
    };


    /**
     *
     * @param {Object} options
     * @private
     */
    ChangePassword.prototype.__setOptions = function ( options ) {
        _.extendOwn( this.options, options || {} );
    };


    // CHANGE PASSWORD PLUGIN DEFINITION
    // =================================

    function Plugin ( option ) {
        return this.each( function () {
            var $this   = $( this );
            var data    = $this.data( 'zg.changePassword' );
            var options = _.extend( {}, ZG_CONFIG || {}, $this.data(), typeof option === 'object' && option );

            if ( !data ) {
                $this.data( 'zg.changePassword', new ChangePassword( this, options ));
            } else if ( option ) {
                data.__setOptions( options );
            }
        } );
    }

    $.fn.zgChangePassword             = Plugin;
    $.fn.zgChangePassword.Constructor = ChangePassword;


    // CHANGE PASSWORD DATA-API
    // ========================

    $( function () {
        $( selector ).each( function () {
            Plugin.call( $( this ) );
        } );
    } );

}( jQuery, _ ));
