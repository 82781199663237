/**
 * Allows to create different delivery method in the same order
 *
 * @author: Pietro Vignola <pvignola[at]kooomo[dot]com>
 */

(function ( $, _ ) { /* global _, handlebarsTemplates, zgGet */
    'use strict';

    var root = this;

    /**
     * @selector data-zg-role="multidelivery" The plugin start for each selector in the dom when the page load
     */

    var selector = '[data-zg-role="multidelivery"]';

    // MULTIDELIVERY CLASS DEFINITION
    // =======================

    var Multidelivery = function ( element, options ) {
        this.$element = $( element );
        this.options  = options;

        this.$elementContainerItems = this.$element.find( this.options.elementContainerItems );

        this.checkAndSetData();
        this.renderLineItem();

        this.setEventHandlers();

        this.options.init = false;
    };

    Multidelivery.DEFAULTS = {
        multipleAddress: [],

        productId: null,
        selectedSku: 0,
        totalQty: 1,
        availableAddresses: {},
        defaultAddress: null,
        dispatchAvailable: false,
        initialQty: null,
        checkAvailability: false,
        disabled: false,

        init: true,

        elementContainerItems:      '[data-zg-role="container-lines"]',
        elementSingleLine:          '[data-zg-role="single-line"]',
        elementDispatchLink:        '[data-zg-action="dispatchLink"]',
        elementDeleteLine:          '[data-zg-action="deleteLine"]',

        elementSingleQuantity:      '[data-zg-role="singleQuantity"]',
        elementSingleAddress:       '[data-zg-role="singleAddress"]',

        templateMultiDeliveryLine:  'multi-delivery-line',

        elementNotAvailable:        '[data-zg-role="notAvailable"]',
        elementSubmitDelivery:      '[data-zg-role="submit-multidelivery"]'
    };

    /**
     * Check and set default data if is not in temp addresses
     */
    Multidelivery.prototype.checkAndSetData = function () {
        var found       = false;

        this.options.dispatchAvailable  = false;
        this.options.totalQty           = 0;

        //If $result->temp_multiple_address is empty create default data
        if( !this.options.multipleAddress ) {
            this.options.multipleAddress = [{
                'address_id': this.options.defaultAddress,
                'qty': this.options.initialQty,
                'availability': true,
                'country_id': root.ZG_CONFIG.country_from_user || ''
            }];
        }

        //Dispatch link
        _.each( this.options.multipleAddress, function ( line ) {
            var qty = parseInt(line.qty);
            this.options.totalQty += qty;

            if( !found && ( qty > 1 ) ){
                found = true;
                this.options.dispatchAvailable = true;
            }
            if( line.availability === void(null) ){
                line.availability = true;
            }
        }, this );

        if( this.options.totalQty === 0 ){
            this.options.totalQty = this.options.initialQty;
        }

        if( !this.options.init ){
            this.refreshMultipleAddress();
        }
    };

    /**
     * Create a new single line
     */
    Multidelivery.prototype.createNewSingleLine = function () {
        var found = false;

        _.each( this.options.multipleAddress, function ( line ) {
            var qty = parseInt(line.qty),
                newLine = {};

            if( !found && ( qty > 1 ) ){
                found = true;
                line.qty = 1;
                newLine = {
                    'address_id': line.address_id || null,
                    'qty': (qty - 1),
                    'availability': true,
                    'country_id': line.country_id
                };
                this.options.multipleAddress.push( newLine );

                this.checkAndSetData();
            }
        }, this );
    };

    /**
     * Delete a single line
     */
    Multidelivery.prototype.deleteSingleLine = function ( index ) {
        if (index > -1) {
            this.options.multipleAddress.splice(index, 1);
        }

        this.checkAndSetData();
    };

    /**
     * Render data in handlebars template
     */
    Multidelivery.prototype.renderLineItem = function () {
        if( this.options ) {
            if (this.$elementContainerItems) {
                this.$elementContainerItems
                    .addClass('loading')
                    .html( handlebarsTemplates.render( this.options.templateMultiDeliveryLine , this.options || []) )
                    .removeClass('loading');
            } else {
                throw new Error('Multidelivery - no container where to render this template: '+ this.options.templateMultiDeliveryLine );
            }
        }
    };

    /**
     * Save all in session - Useful if user reload the page
     */
    Multidelivery.prototype.refreshMultipleAddress = function () {
        var multiple_address    = {},
            complexId           = this.options.productId+'.'+this.options.selectedSku;

        $( document ).find( '[data-zg-role="multidelivery"]' ).each( function () {
            var $this = $(this),
                options;

            if (typeof $this.data('zg.multidelivery') !== 'undefined'){
                options = $this.data('zg.multidelivery').options;
                multiple_address[options.productId+'.'+options.selectedSku] = options.multipleAddress;
            }
        } );

        var data = { 'multiple_address': multiple_address };

        zgGet( 'refreshMultipleAddress', data, null, { success: (function (multiple_address) {
            // Overwrite the multiple_address of the current complexId by the new one received (with availability)
            if( multiple_address && multiple_address.multiple_address[complexId] ) {
                this.options.multipleAddress = multiple_address.multiple_address[complexId];
            }

            // Render current product address lines again
            this.renderLineItem();

            // Validate proceed link
            this.validateProceed();
        }).bind( this ) } );
    };

    /**
     * Update value in address_id or quantity
     */
    Multidelivery.prototype.updateValue = function ( referer ) {
        if( referer.key === 'qty' && referer.value < 1 ){
            referer.value = 1;
        }

        this.options.multipleAddress[referer.index][referer.key] = referer.value;
        if( referer.country_id ) {
            this.options.multipleAddress[referer.index].country_id = referer.country_id;
        }

        this.checkAndSetData();
    };

    /**
     * Enable or disable proceed button
     */
    Multidelivery.prototype.validateProceed = function () {
        if( $( document ).find( this.options.elementNotAvailable ).length >= 1 ){
            $( this.options.elementSubmitDelivery ).addClass( 'disabled' );
        }
        else {
            $( this.options.elementSubmitDelivery ).removeClass( 'disabled' );
        }
    };

    /**
     * @method setEventHandlers
     */
    Multidelivery.prototype.setEventHandlers = function () {
        //Click on dispatch link should create a new single line
        this.$element.on( 'click.zg.multidelivery', this.options.elementDispatchLink, (function ( e ) {
            e.preventDefault();

            this.createNewSingleLine();
        }).bind( this ) );

        //Click on delete single line
        this.$element.on( 'click.zg.multidelivery', this.options.elementDeleteLine, (function ( e ) {
            e.preventDefault();

            var index = $( e.currentTarget ).data( 'index' ) || 0;

            this.deleteSingleLine( index );
        }).bind( this ) );

        //On change/input quantity or addresses in single line
        this.$element.on( 'input.zg.multidelivery', this.options.elementSingleQuantity + ',' + this.options.elementSingleAddress, (function ( e ) {
            e.preventDefault();

            var $field  = $( e.currentTarget ),
                referer = {
                    index:      $field.closest( this.options.elementSingleLine ).data( 'index' ) || 0,
                    key:        $field.data('key'),
                    value:      $field.val(),
                    country_id: $field.find('option:selected').data('countryId')
                };

            this.updateValue( referer );
        }).bind( this ) );
    };

    // MULTIDELIVERY PLUGIN DEFINITION
    // ========================

    function Plugin ( option ) {
        return this.each( function () {
            var $this = $( this );
            var data = $this.data( 'zg.multidelivery' );
            var options = $.extend( {}, Multidelivery.DEFAULTS, window.ZG_CONFIG || {}, $this.data(), typeof option === 'object' && option );

            if ( !data ) {
                $this.data( 'zg.multidelivery', new Multidelivery( this, options ) );
            }
        } );
    }

    $.fn.zgMultidelivery = Plugin;
    $.fn.zgMultidelivery.Constructor = Multidelivery;

    // MULTIDELIVERY DATA-API
    // ===============

    $( function () {
        $( selector ).each( function () {
            Plugin.call( $( this ) );
        } );
    } );

}.call( this, jQuery, _ ));