/*
 * zg-cms-highlighter.js
 *
 * Plugin to provide information about the CMS on the front end to admins
 *
 * @author: @author: Kev Prunty <kprunty [at] kooomo [dot] com>
 * @author: Dario Lopez <dlopez[at]kooomo.com>
 *
 */


(function ( $, _ ) {
	'use strict';

	// Establish the root object ('window' in the browser)
	var root = this;

	/**
	 * @selector data-zg-role="SELECTOR" The plugin start if there is the selector in the dom when the page load
	 */
	var SELECTOR = '[data-zg-role="cms-highlighter-widget"]';


	// SCRIPT OPTIONS

	/**
	 * @param {string}  [filterContainer] Container of the filters html
	 * @param {boolean} [forceFilters] Initialize filter plugin even if we didn't request filters. To be used with front end filtering
	 * @param {string}  [initialFilters]
	 */
	var DEFAULTS = {
		toggleButton:         '[data-zg-role="cms-highlighter-toggle"]',
		contentIdContainer:   'data-cms-highlighter-content-id',
		highlighterTemplate:  'cms-highlighter',
		contentTemplate:  	  'cms-highlighter-content',
		getCmsContent: 		  '[data-zg-role="get-cms-content"]',
		highlighterContent:	  '[data-zg-role="cms-highlighter-content"]',
		modalBody: 		 	  '[data-zg-role="cms-highlighter-modal-body"]',
		parentUrl:	 	 	  '[data-zg-role="cms-highlighter-parent-url"]',
		jumbotron: 		 	  '[data-zg-role="cms-highlighter-jumbotron"]',
		jumbotronClose: 	  '[data-zg-role="cms-highlighter-jumbotron-close"]',
		parentSelect: 	  	  '[data-zg-role="cms-highlighter-parent-select"]',
		jumbotronDisplayed:	  false
	};


	// CMS-HIGHLIGHTER-PLUGIN CLASS DEFINITION
	// ===========================

	/**
	 * @param {HTMLElement} element
	 * @param {!Object}     options
	 *
	 * @constructor
	 */
	var CMS_Highlighter = function ( element, options ) {
		this.$element = $( element );
		this.options = _.clone( DEFAULTS );

		this.$contentIdContainers = $( '[' + this.options.contentIdContainer + ']' );
		this.$modalBody = $( this.options.modalBody );
		this.$jumbotron = $( this.options.jumbotron );
		this.requestUrl = window.makeUrl({module: 'eshop', manager: 'eshop', action: 'getcontentCMS'});

		this.init();
		this.__setEventHandlers();
	};

	/**
	 *
	 * @returns {Object}
	 */
	CMS_Highlighter.prototype.init = function () {
        this.$element.removeClass( 'd-none' );
	};

	/**
	 *
	 * @returns {Object}
	 */
	CMS_Highlighter.prototype.__addControls = function () {
		var that = this;

		// Checking if there are new highlighter containers (async rendered)
		var lengthCurrentContainers = $( '[' + this.options.contentIdContainer + ']' ).length;
		if( this.$contentIdContainers.length != lengthCurrentContainers ) {
			this.$contentIdContainers = $( '[' + this.options.contentIdContainer + ']' );
		}

		_.each( this.$contentIdContainers, function ( container ){
			var data = {};
			data.contentId = $( container ).attr( that.options.contentIdContainer );
			$( container ).append( handlebarsTemplates.render( that.options.highlighterTemplate, data));
		});
	};


	CMS_Highlighter.prototype.__removeControls = function () {
		$( this.options.highlighterContent ).remove();
	}


	CMS_Highlighter.prototype.__getCMSContent = function( contentId ){

        $.ajax({
            url: this.requestUrl,
            type: 'GET',
            context: this,
            data: {
                contentId: contentId
            },
            cache: true,
            beforeSend: function(){
            	this.$modalBody.empty();
                this.$modalBody.addClass( 'loading' );
            },
			complete: function() {
            	this.$modalBody.removeClass( 'loading' );
			},
            success: function ( response ) {
            	var cmsContentData = response[contentId];
            	this.__parseCMSContent( cmsContentData );
			},
			error: (function ( response ) {
				this.onError( response );
			}).bind( this )
        });
    };


	CMS_Highlighter.prototype.onError = function ( response ) {
		if ( root.DEBUG ) {
			console.log( 'CMS_Highlighter - ERROR', response );
		}

		$( document ).trigger( 'zg-error', [{
			eventType: 'CMS_Highlighter',
			message:   root.JS_TRANSLATIONS.genericErrorMsg
		}] );

		$( document ).trigger( 'zg.getCMSContent.error' );
	};


	CMS_Highlighter.prototype.__parseCMSContent = function( cmsContentData ){
		var that = this;

        cmsContentData.editURL = window.makeUrl({
            module: 'admin/cmscontent',
            action: 'edit',
            id: cmsContentData.id
        });

        Object.keys(cmsContentData.fields).forEach(function(field){
            if(typeof cmsContentData.fields[field] === 'object'){
                cmsContentData.fields[field].numItems = Object.keys(cmsContentData.fields[field]).length;
                cmsContentData.fields[field].type = 'object';
            }
        });

        // Checking if it has an aFatherId array
        if( !!cmsContentData.aFatherId && !!cmsContentData.aFatherId[0] ) {

        	//If it has more than one parent
        	if( cmsContentData.aFatherId.length > 1 ) {
        		cmsContentData.multiParents = [];
        		var contParents = 0;

        		_.each( cmsContentData.aFatherId, function ( itemFatherId ){

					$.ajax({
						url: that.requestUrl,
						type: 'GET',
						context: this,
						data: {
							contentId: itemFatherId
						},
						cache: true,
						complete: function() {
							// Only rendering when we have looped through all the aFatherId array items
							contParents++;
							if( contParents == cmsContentData.aFatherId.length ) {
								that.__renderContent(cmsContentData);
							}
						},
						success: function ( response ) {
							cmsContentData.multiParents.push( {
								id: response[ itemFatherId ].id,
								name: response[ itemFatherId ].name,
								editURL: that.__createEditURL( response[ itemFatherId ].id )
							} );
						},
						error: (function ( response ) {
							this.onError( response );
						}).bind( this )
					});
				});

			}
        	else {
        		cmsContentData.editParentURL = this.__createEditURL( cmsContentData.aFatherId[0] );

        		this.__renderContent( cmsContentData );
			}
		}
        else {
        	// No parent
        	this.__renderContent( cmsContentData );
		}

    };


	CMS_Highlighter.prototype.__createEditURL = function ( contentId ) {
		return window.makeUrl({
			module: 'admin/cmscontent',
			action: 'edit',
			id: contentId
		});
	};


	CMS_Highlighter.prototype.__renderContent = function( cmsContentData ){
        this.$modalBody.append(
            handlebarsTemplates.render( this.options.contentTemplate, cmsContentData )
        );
    };


	/**
	 *
	 * @param options
	 */
	CMS_Highlighter.prototype.updateOptions = function ( options ) {
		_.extendOwn( this.options, options || {} );
		_.extendOwn( this.request, options || {} );
	};


	/**
	 *
	 * @private
	 */
	CMS_Highlighter.prototype.__setEventHandlers = function () {
		var that = this;

		this.$element.on( 'zg.CMS_Highlighter.updateOptions', (function ( e, options ) {
			this.updateOptions( options );
		}).bind( this ) );

		this.$element.on( 'click.zg.CMS_Highlighter.toggled', (function ( e ) {
			// The controls will be added/removed every time the Highlighter button is toggled
			// Doing this will help us to be sure we are getting new asyc elements added to the page
			if( this.$element.hasClass( 'toggled' ) ) {
				this.$element.removeClass( 'toggled' );
				that.__removeControls();
			}
			else {
				this.$element.addClass( 'toggled' );
				that.__addControls();
			}

			// We will add a 'cms-highlighted_relative' CSS class to
			// the Containers withe the data-relative. When we toggle the widget
			// we will remove that class.
			_.each( this.$contentIdContainers, function ( container ){
				if( $( container ).data( 'relative' ) ){
					if( $( container ).hasClass( 'cms-highlighted' ) ) {
						$( container ).removeClass( 'cms-highlighted_relative' );
					}
					else {
						$( container ).addClass( 'cms-highlighted_relative' );
					}
				};

				if( $( container ).data( 'small' ) ){
					$( container ).addClass( 'cms-highlighted_small' );
				};
			})

			this.$contentIdContainers.toggleClass( 'cms-highlighted' );

			if( !this.options.jumbotronDisplayed ) {
				this.$jumbotron.addClass( 'cms-highlighter__jumbotron-show' );
				this.options.jumbotronDisplayed = true;
				setTimeout( function (){
					that.$jumbotron.removeClass( 'cms-highlighter__jumbotron-show' );
				}, 5000 );
			}
		}).bind( this ) );

		$( document ).on( 'click.zg.CMS_Highlighter.getCmsContent', this.options.getCmsContent, function( e ) {
			e.preventDefault();
			var contentId = $( this ).data( 'contentId' );
			that.__getCMSContent( contentId );
		});

		$( document ).on( 'change.zg.CMS_Highlighter.parentSelect', this.options.parentSelect, function () {
			// Opening a new tab with the parent link selected from the multi parent select
			var parentLink = $( this ).find( ':selected' ).val();
			var newTab = window.open(parentLink, '_blank');
			if( newTab ){
				newTab.focus();
			}
			else{
				$( document ).trigger( 'zg-warning', { message: JS_TRANSLATIONS.newTabNotAllowed } );
			}
		});

		$( document ).on( 'click.zg.CMS_Highlighter.jumbotron.close', this.options.jumbotronClose, function ( e ) {
			that.$jumbotron.removeClass( 'cms-highlighter__jumbotron-show' );
		});
	};


	// CMS-HIGHLIGHTER-PLUGIN DEFINITION
	// ============================

	function Plugin ( option, updateOptions ) {
		return this.each( function () {
			var $this   = $( this );
			var data    = $this.data( 'zg.CMS_Highlighter' );
			var options = $.extend( {}, root.ZG_CONFIG || {}, $this.data(), typeof option === 'object' && option );

			if ( !data ) {
				$this.data( 'zg.CMS_Highlighter', (data = new CMS_Highlighter( this, options )) );
			} else if ( updateOptions && typeof option === 'object' ) {
				data.updateOptions( option );
			}

		} );
	}

	$.fn.zgCMS_Highlighter             = Plugin;
	$.fn.zgCMS_Highlighter.Constructor = CMS_Highlighter;


	// CMS-HIGHLIGHTER-PLUGIN DATA-API
	// ===================

	$( function () {
		$( SELECTOR ).each( function () {
			Plugin.call( $( this ) );
		} );
	} );

}.call( this, jQuery, _ ));
