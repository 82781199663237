/* =====================================================================================================================
 * processProductImages
 * =====================================================================================================================
 *
 *   Processes the images object for each product so we have something usable for the gallery and zoom scripts.
 *
 *   This code should ONLY be executed in the product page (and maybe for the quick-buy).
 *
 *   I know this is crazy code, but until the backend code is refactored (and it won't be refactored) it's the only way
 *   to get an usable object  :(
 *
 *   Just ignore this code and think happy thoughts  ;)
 *
 * =====================================================================================================================
 * @author: David Pocina <dpocina[at]zerogrey[dot]com>
 * ================================================================================================================== */


(function () {
	'use strict';

	// Establish the root object (`window` in the browser)
	var root = this;

	var keys = ['first', 'second', 'third', 'fourth', 'fifth', 'sixth', 'seventh', 'eighth', 'ninth'];


	/**
	 *
	 * @param {Object} images
	 * @returns {Array}
	 */
	function processProductImages ( images ) {
		var imageKey,
			keyObj,
			processed = [];

		for ( imageKey in images ) {
			if ( images.hasOwnProperty( imageKey ) ) {
				keyObj = __generateKeyObj( imageKey );

				__generateImgObj(
					processed,
					keyObj.index,
					keyObj.key,
					images[imageKey]
				);
			}
		}

		return processed;
	}


	/**
	 *
	 * @param {string} imageKey
	 * @returns {{index: number, key: string}}
	 * @private
	 */
	function __generateKeyObj ( imageKey ) {
		var found  = false,
			i,
			keyObj = {
				'index': 0,
				'key':   imageKey
			};

		for ( i = 0; i < keys.length && !found; i++ ) {
			if ( imageKey.indexOf( keys[i] ) === 0 ) {
				keyObj.index = (i + 1);
				keyObj.key   = imageKey.replace( keys[i], '' );

				found = true;
			}
		}

		return keyObj;
	}


	/**
	 *
	 * @param {Array}  array
	 * @param {number} index
	 * @param {string} key
	 * @param {string} val
	 * @private
	 */
	function __generateImgObj ( array, index, key, val ) {
		if ( !array[index] ) {
			array[index] = {};
		}

		array[index][key] = val;
	}


	// -----------------------------------------------------------------------------------------------------------------


	root.zgProcessProductImages = processProductImages;

}.call( this ));
