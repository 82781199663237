/**
 *
 * Plugin for send email with details of bank transfer. Used in thank you page (when the client select bank transfer for pay)
 *
 * @author: Botond Szasz <bszasz@zerogrey.com>
 *
 */

(function ($) {

    "use strict";

    /**
     * @selector data-zg-role="send-bank-transfer" The plugin start if there is the selector (button) in the dom when the page load
     */

    var toggle = '[data-zg-role="send-bank-transfer"]';

    var ZG_SendBankTransfer = function (element, config) {
            var self = this;
            this.init = function (element, config) {
                var $element = $(element);
                self.$eventBus = $(document);
                self.$element = $element;
                self.order_id = self.$element.data('orderId');
                self.$element.on('click', self.sendBt);
            };

            // function to interact with ajax function - sendBankTransferDetailsEmail();
            this.sendBt = function () {

                var request = {
                    order_id: self.order_id
                };

                var url = window.makeUrl({
                    module: 'eshop',
                    action: 'sendBankTransferDetailsEmail'
                });

                $.getJSON(url, request, function (response) {
                    
                    if ('success' === response.status) {
                        self.$eventBus.trigger('zg-notification', [{
                            eventType: 'bank-transfer-email-sent',
                            message: response.message
                        }]);
                    } else {
                        self.$eventBus.trigger('zg-error', [{
                            eventType: 'bank-transfer-email-sent',
                            message: response.message
                        }]);
                    }
                });
            };

            this.init(element, config);
        };

    $.fn.zg_send_bank_transfer = function (config) {
        return this.each(function () {
            var $this = $(this);
            var data = $this.data('zgSendBankTransfer');
            if (!data) {
                $this.data('zgSendBankTransfer', (data = new ZG_SendBankTransfer(this, config)));
            }
        });
    };

//    $(function () {
        $('body').find(toggle).zg_send_bank_transfer(window.ZG_CONFIG);
//    });

})(jQuery);