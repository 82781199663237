/**
 * Plugin for managing subscription plans on the eshop
 */

/* global  JS_TRANSLATIONS, zgPost */

(function ($, _) {

    'use strict';

    var root = this;

    /**
     * @selector The plugin start if there is the selector in the dom when the page load
     */

    var SELECTOR = '[data-zg-action="select-subscription-plan"]';

    /**
     * @param {string} [subscriptionPlanId] Subscription Id of the plan to be subscribed
     */
    var DEFAULTS = {
        subscriptionPlanId: null,
        proceedToPurchaseButton : '[data-zg-role="proceedToPurchase"]'
    };

    /**
     *
     * @param {HTMLElement} element
     * @param {!object}     options
     *
     * @constructor
     */

    var Subscription = function ( element, options ) {

        this.$element                   = $( element );
        this.options                    = _.extendOwn( _.clone( DEFAULTS ), options || {} );

        this.__init();
    };

    Subscription.prototype.__init = function () {

    };

    // Subscription.prototype.__eventHandlers = function () {
    //     var that = this;

        // if( that.$element.attr('id') == 'address_form_modal' ) {
        //     $(document).on('zg.addressForm.ready', function (data) {
        //         var fields = [
        //             {element: "addr_1", field: "Line1"},
        //             {element: "addr_2", field: "Line2", mode: pca.fieldMode.POPULATE},
        //             {element: "city", field: "City", mode: pca.fieldMode.POPULATE},
        //             {element: "country_id", field: "ProvinceName", mode: pca.fieldMode.POPULATE},
        //             {element: "post_code", field: "PostalCode"},
        //             {element: "state_id", field: "CountryName", mode: pca.fieldMode.COUNTRY}
        //         ];
        //         that.__initPca( fields );
        //     });
        // }

    // };

    /**
     * remove Address by Id
     *
     * @private
     */
    Subscription.prototype.__subscribeToPlan = function () {
        // var that = this;

        if ( this.options.subscriptionPlanId ) {
            zgPost(
                'selectSubscriptionPlan',
                { subscription_plan_id: this.options.subscriptionPlanId },
                { error: JS_TRANSLATIONS.eshop_cart_subscription_plan_id_selection_failed,
                  success: JS_TRANSLATIONS.eshop_cart_subscription_plan_id_selection_success
                },
                {
                    success: function () {
                        // $( that.options.proceedToPurchaseButton ).removeClass('disabled');
                        $('body').addClass('loading');
                        location.href = window.makeUrl({module: 'eshop', manager: 'confirm', action: 'list'});
                    },
                    error: function () {
                        location.href = window.makeUrl({module: 'eshop', manager: 'cart', action: 'list'});
                    }
                }
            );
        } else {
            throw new Error( 'Subscription.__subscribeToPlan FAILED - please specify a subscriptionPlanId' );
        }
    };



    function Plugin ( option ) {
        return this.each( function () {
            var $this   = $( this );
            var data    = $this.data( 'zg.subscription' );
            var options = $.extend( {}, root.ZG_CONFIG || {}, $this.data(), typeof option === 'object' && option );

            if ( !data ) {
                $this.data( 'zg.subscription', data = new Subscription( this, options ) );
            }

            data.__subscribeToPlan();
        } );
    }

    $.fn.zg_subscription             = Plugin;
    $.fn.zg_subscription.Constructor = Subscription;

    /**
     * Add listener to on click
     * @method document
     * @listen click.zg.subscribeToPlan On click of SELECTOR starts the plugin
     */

    $( function () {
        $(document).on('click.zg.subscribeToPlan', SELECTOR, function ( e ) {
            e.preventDefault();
            Plugin.call( $( this ) );
        });
    } );


}).call( this, jQuery, _ );
